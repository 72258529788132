import {Token} from './../../../../core/auth/_models/token.model';
// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject, Subscription, timer} from 'rxjs';
// Translate
// import {TranslateService} from '@ngx-translate/core';
// Auth
import {AuthNoticeService, AuthService} from '../../../../core/auth';
import {first, map, skipWhile, take} from 'rxjs/operators';
import {UserQuery} from '@core/query/user.query';
// import { UserStore } from '@core/store/user.store';


@Component({
  selector: 'kt-confirm',
  templateUrl: './confirm.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ConfirmComponent implements OnInit, OnDestroy {
  // Public params
  loginForm: FormGroup;
  loading = false;
  sendingCode = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];

  private unsubscribe: Subject<any>;

  private returnUrl: any;

  private fullPhone: string;

  private tokenSubscription: Subscription;
  error = '';

  timer$: Observable<number>;

  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    // private translate: TranslateService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private query: UserQuery
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit(): void {
    this.initLoginForm();

    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl || '/';
    });

    this.fullPhone = this.route.snapshot.paramMap.get('phone');
    if (this.fullPhone == null) {
      this.router.navigate(['/auth/login']);
      return;
    }
    this.authNoticeService.setDescription('Telefon numarana gelecek kodla Askipo’ya giriş yap');
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initLoginForm() {
    // demo message to show

    this.loginForm = this.fb.group({
      otp: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(6),
          Validators.minLength(6)
        ])
      ]
    });
  }

  async resendCode() {
    if (this.timer$ && await this.timer$.pipe(first()).toPromise() > 0) {
      return;
    }
    this.timer$ = timer(0, 1000).pipe(map((tick) => {
      return 60000 - (tick * 1000);
    }));
    this.sendingCode = true;
    const phone = '+9' + this.fullPhone
      .replace('(', '')
      .replace(')', '')
      .replace(/\s/g, '');

    await this.auth.sendPassword('none', phone);
    this.authNoticeService.setNotice('Cep telefonuna kod tekrar gönderildi.', 'info');
    this.sendingCode = false;
  }

  async submit() {
    const controls = this.loginForm.controls;
    this.error = '';
    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.error = 'Geçersiz bir kod girdin';
      this.cdr.markForCheck();
      return;
    }
    this.loading = true;

    const phone = '+9' + this.fullPhone
      .replace('(', '')
      .replace(')', '')
      .replace(/\s/g, '');

    const tokenRef = await this.auth.checkToken(controls.otp.value, phone);

    if (!tokenRef.status) {
      this.error = 'Yanlış bir kod girdin';

      this.loading = false;
      this.cdr.markForCheck();
      return;
    } else {
      const authResult = await this.auth.login(tokenRef.token, tokenRef.id);
      this.loading = false;
      if (authResult) {
        // console.log(authResult);
        const res = await this.query.user$.pipe(skipWhile(user => !user), take(1)).toPromise();
        // console.log('res:', res);
        this.router.navigate(['/dashboard/']);
      } else {
        console.error('failure', authResult);
      }
    }
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
