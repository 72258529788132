import { ThreadService } from './../../../../../core/services/thread.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kt-calling',
  templateUrl: './calling.component.html',
  styleUrls: ['./calling.component.scss']
})
export class CallingComponent implements OnInit {

  @Input() threadID: string;
  @Input() type: string;

  @Input() call: {
    state: 'calling' | 'called' | 'oncall',
    video: boolean,
    voice: boolean;
  };

  public status = {
    audio: true,
    mic: true
  };

  constructor(
    private threadService: ThreadService
  ) { }

  ngOnInit() {
  }

  public changeStatus(type: string) {
    this.status[type] = !this.status[type];
    this.threadService.callStatusChange(this.threadID, type, this.status[type]);
  }

  public close() {
    // console.log('closed', this.threadID);
    this.threadService.callChange(this.threadID, this.type, 0);
  }

  public async accept() {
    await this.threadService.callChange(this.threadID, this.type, 2);
    this.call.state = 'oncall';
  }

}
