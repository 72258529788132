import { combineLatest, Subscription } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin } from 'rxjs';
// Environment
import { environment } from '../../../../environments/environment';
// import {environment, apiURL} from '@environments/environment';

import { HttpUtilsService } from '../../_base/crud';
// Models
import { User } from '../_models/user.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserStore } from '../../store/user.store';
import { first, take } from 'rxjs/operators';
import { UserPackage } from '@core/models/user_packages.model';

import * as firebase from 'firebase/app';
import { PatientService } from '@core/services/patient.service';
import { MenuAsideService, MenuConfigService } from '@core/_base/layout';
import { AngularFireAnalytics } from '@angular/fire/analytics';
// import {PaymentService} from '@core/services/payment.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    // private httpUtils: HttpUtilsService,
    private afAuth: AngularFireAuth,
    private fireStore: AngularFirestore,
    private userStore: UserStore,
    // private patientService: PatientService,
    private menuAsideService: MenuAsideService,
    private fAnalytics: AngularFireAnalytics,
    // private paymentService: PaymentService
    private fireStorage: AngularFireStorage
  ) {
  }

  private token: string;
  private uid: string;

  userSubscription: Subscription;
  packSubscription: Subscription;

  public signInAnonymously() {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInAnonymously().then(async (value) => {
        this.uid = value.user.uid;
        this.token = await value.user.getIdToken();
        resolve(true);
      }).catch(() => reject());
    });
  }

  public get authState() {
    return this.afAuth.authState;
  }

  public async emailControl(email: string) {
    const httpResult = await this.http.post(
      environment.apiURL + 'app/account/was-registered-email', { email: email }
    ).toPromise();

    if (httpResult['status'] === 'failure') {
      return;
    }

    return httpResult['wasRegistered'];
    //return this.fireStore.collection('users').ref.where('email', '==', email).get();
  }

  public async sendPassword(UID: string, phone: string) {
    if (UID === 'none') {
      const result = await this.fireStore.collection('users')
        .ref.where('phone_number', '==', phone)
        .where('is_doctor', '==', false).get();
      UID = result.docs[0].id;
    }

    return this.http.post(environment.cloudFunctionUrl + 'sendPassword', {
      UID,
      phone,
      ip: (await this.ipAddress) || '',
      tokenUID: this.uid ? this.uid : UID
    }, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token,
      })
    }).toPromise();
  }

  // Authentication/Authorization
  async login(token: string, id: string, sudo = false) {
    const result = await this.afAuth.auth.signInWithCustomToken(token);
    if (!sudo) {
      this.fireStore.collection('tokens').doc(id).delete();
    }

    const state = !!result.user;

    if (state) {
      this.subscribeUser();
    }

    return {
      state,
      user: result.user
    };
  }

  async registerPatient(phone: string, displayName: string, email: string, birthDate: number) {
    return this.http.post(environment.cloudFunctionUrl + 'createUser', {
      phone,
      displayName,
      email,
      birthDate,
      role: 'patient',
      ip: (await this.ipAddress) || ''
    }).toPromise();
  }

  async uploadCV(file: File) {
    const randomFileName = Math.random().toString(16).substr(2, 64);
    const extension = file.name.split('.')[1];
    const cvRef = this.fireStorage.ref(`cv_files/${randomFileName}.${extension}`);
    const snapshot = await cvRef.put(file);
    const result = await snapshot.ref.getDownloadURL();
    return result;
  }

  async registerDoctor(
    phone: string,
    firstName: string,
    lastName: string,
    email: string,
    school: string,
    dietExperience: string,
    onlineDietExperience: string,
    numberOfFollowers: string,
    socialMediaLinks: string,
    cvUrl: string,
    city: string,
    district: string,
  ) {
    return this.http.post(environment.cloudFunctionUrl + 'createUser', {
      phone,
      firstName,
      lastName,
      role: 'doctor',
      email,
      school,
      ip: (await this.ipAddress) || '',
      dietExperience,
      onlineDietExperience,
      numberOfFollowers,
      socialMediaLinks,
      cvUrl,
      city,
      district
    }).toPromise();
  }

  public unsubscribeUser() {
    if (this.userSubscription != null) {
      this.userSubscription.unsubscribe();
    }
    if (this.packSubscription != null) {
      this.packSubscription.unsubscribe();
    }
    this.userStore.update(() => ({
      appointment: null,
      user: null,
      package: null,
      merchant: {
        contactName: '',
        contactSurname: '',
        iban: ''
      }
    }));
  }

  public async subscribeUser() {
    this.unsubscribeUser();
    this.afAuth.authState.pipe(take(1)).subscribe(async (auth) => {
      if (auth) {
        if (auth.isAnonymous) {
          this.logout();
        } else {
          const _user = this.fireStore
            .collection('users')
            .doc(auth.uid)
            .valueChanges();
          this.userSubscription = _user
            .subscribe((user: User) => {
              if (!user.is_doctor) {
                if (this.packSubscription) {
                  this.packSubscription.unsubscribe();
                }

                const _pack = this.fireStore
                  .collection('package_tracking', query => query.orderBy('start_date', 'desc').where('assigned_user.uid', '==', auth.uid))
                  .valueChanges({ idField: 'id' });


                this.packSubscription = _pack.subscribe((pack: any) => {
                  console.log('girdi');
                  if (!pack[0]) {
                    this.userStore.update({
                      user: {
                        ...user,
                        uid: auth.uid
                      }
                    });
                    this.menuAsideService.loadMenu(user.is_doctor);
                    return;
                  }

                  const now = firebase.firestore.Timestamp.now().toMillis();
                  const lastDate = +new Date(pack.start_date + (pack.months * 2592000000));

                  if (now > lastDate) {
                    this.userStore.update({
                      user: {
                        ...user,
                        uid: auth.uid
                      },
                      package: {
                        ...pack[0],
                      }
                    });
                  } else {
                    this.userStore.update(() => ({
                      user: {
                        ...user,
                        uid: auth.uid
                      },
                      package: {
                        ...pack[0],
                      }
                    }));
                  }

                  this.fbaInit();
                  this.menuAsideService.loadMenu(user.is_doctor);
                  // this.menuAsideService.loadMenu(user.is_doctor);
                });

              } else {
                this.userStore.update({
                  user: {
                    ...user,
                    uid: auth.uid
                  }
                });
                this.menuAsideService.loadMenu(user.is_doctor);
                // this.paymentService.getMerchantDetails().then((merchant) => {
                //   this.userStore.update(() => ({ merchant: merchant['body'] }));
                // });
              }
            });
          // combineLatest([_user, _pack]).subscribe(([user, pack]) => {
          //   this.userStore.update({
          //     user: {
          //       ...user as User,
          //       uid: auth.uid
          //     },
          //     package: pack as UserPackage[]
          //   });
          // });
        }
      }
    });
  }

  public async fbaInit() {
    const user = this.userStore.getValue().user;
    console.log(user);

    if (user.is_doctor) {
      return false;
    }

    try {
      await this.fAnalytics.setUserId(user.uid);
      this.fAnalytics.setUserProperties({
        display_name: user.display_name,
        phone_number: user.phone_number,
        email: user.email,
        register_date: new Date(user.register_date).toLocaleDateString()
      });
    } catch (error) {
      console.log('Firebase analitik user property ayarlanırken hata oluştu.', error);
    }
  }

  logout() {
    return this.afAuth.auth.signOut();
  }

  public async getUserIdByPhone(phone: string) {
    phone = '+9' + phone
      .replace('(', '')
      .replace(')', '')
      .replace(/\s/g, '');
    const userRef = await this.fireStore.collection('users').ref
      .where('phone_number', '==', phone)
      .get();

    return userRef.docs[0].id;
  }

  public async wasRegistered(phone: string) {
    const httpResult = await this.http.post<any>(
      environment.apiURL + 'app/account/was-registered-doctor', { phoneNumber: phone }
    ).toPromise();

    if (httpResult.status === 'failure') {
      return;
    }

    console.log(httpResult);
    const isRegistered = httpResult.body === 'registered-user';
    const isDoctor = httpResult.body !== 'unauthorized-account';

    if (isRegistered) {
      return {
        empty: !httpResult.wasRegistered,
        UID: httpResult.wasRegistered ? httpResult.uid : '',
        isDoctor,
        enabledAccounnt: httpResult.enabled,
        preApproved: httpResult.preApproved,
      };
    }

    return {
      empty: !httpResult.wasRegistered,
      UID: '',
      isDoctor,
    };
  }

  public async checkToken(code: string, phone: string): Promise<{
    status: boolean,
    token?: string,
    id?: string,
  }> {
    try {
      const result = await this.http.post<any>(
        environment.apiURL + 'app/account/check-token', {
        code,
        phone
      }
      ).toPromise();

      const isRequestSuccessful = result.status === 'success';

      return {
        status: isRequestSuccessful,
        token: isRequestSuccessful ? result.body.token : '',
        id: isRequestSuccessful ? result.body.id : '',
      };
    } catch {
      return { status: false };
    }
  }

  public getContracts() {
    return this.fireStore.collection('general').doc('contracts').ref.get();
  }

  public get tokens() {
    return this.fireStore.collection('tokens').valueChanges({
      idField: 'id'
    });
  }

  public get ipAddress() {
    return this.http.get(environment.ipURL).toPromise() as Promise<string>;
  }
}
