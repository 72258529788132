import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/auth';

@Component({
  selector: 'kt-sudo',
  templateUrl: './sudo.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SudoComponent implements OnInit, OnDestroy {
  private readonly activatedRoute: ActivatedRoute;
  private readonly authService: AuthService;
  private readonly router: Router;

  constructor(activatedRoute: ActivatedRoute, authService: AuthService, router: Router) {
    this.activatedRoute = activatedRoute;
    this.authService = authService;
    this.router = router;
  }

  ngOnInit() {
    const token = this.activatedRoute.snapshot.paramMap.get('token');
    this.authService.login(token, '', true).then(() => {
      this.router.navigate(['/dashboard/']);
    });
  }

  ngOnDestroy(): void {
  }
}
