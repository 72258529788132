// Angular
import {Injectable} from '@angular/core';
// RxJS
import {BehaviorSubject} from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import {MenuConfigService} from './menu-config.service';
import {first} from 'rxjs/operators';
import {UserStore} from '@core/store/user.store';

@Injectable({
  providedIn: 'root'
})
export class MenuAsideService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private menuConfigService: MenuConfigService,
    private store: UserStore,
  ) {
  }

  loadMenu(isDoctor: boolean) {
    const path = isDoctor ? 'aside.doctor' : 'aside.patient';
    this.menuConfigService.onConfigUpdated$.pipe(first()).subscribe((cfg) => {
      let menuItems: any[] = objectPath.get(cfg, path);
      menuItems = menuItems.filter(el => !el.roles || (el.roles.indexOf(this.store.getValue().user.role) !== -1));
      if (!this.store.getValue().user.is_doctor && !this.store.getValue().package) {
        console.log('paketi yok');
        console.log(menuItems);
        menuItems.splice(menuItems.findIndex((item) => item.page === '/mymenus'), 1);
        menuItems.splice(menuItems.findIndex((item) => item.page === '/chat/view'), 1);
      }
      this.menuList$.next(menuItems);
    });
  }
}
