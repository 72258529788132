import { Component, OnInit, Input, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
// import { TokboxService } from '../../../../core/services/tokbox.service';
import { ThreadService } from '../../../../core/services/thread.service';
import { map } from 'rxjs/operators';
import { UserStore } from '@core/store/user.store';
import { AngularFirestore } from '@angular/fire/firestore';
import { AgoraService } from '@core/services/agora.service';

@Component({
  selector: 'kt-webrtc-audio',
  templateUrl: './webrtc-audio.component.html',
  styleUrls: ['./webrtc-audio.component.scss']
})
export class WebrtcAudioComponent implements OnInit {
  @Input()
  public threadID: string;

  @Input()
  public userUID: string;

  @Input()
  public isDoctor: boolean;

  public infoElement: HTMLElement;
  public remoteElement: HTMLMediaElement;
  public connectionElement: HTMLElement;

  public remoteStatus: boolean;

  public micStatus: boolean;
  public audioStatus: boolean;
  public pipStatus: boolean;

  private threadSub: Subscription;

  public time = {
    count: 0,
    text: '00:00:00',
    interval: null
  };
  public otherUser: Observable<any>;

  constructor(
    private threadService: ThreadService,
    private store: UserStore,
    private elRef: ElementRef,
    private fireStore: AngularFirestore,
    private cd: ChangeDetectorRef,
    public agoraService: AgoraService
  ) {
    this.micStatus = true;
    this.audioStatus = true;
  }

  async ngOnInit() {
    this.otherUser = this.fireStore.collection('public_users').doc(this.userUID).valueChanges();
    const user = this.store.getValue().user;

    this.agoraService.video = false;
    this.agoraService.audio = true;
    await this.agoraService.initialize();

    if (!this.isDoctor) {
      this.threadSub = this.threadService.thread(this.threadID).pipe(
        map((value) => {
          this.remoteStatus = value['connect_status']['doctor'];

          if (this.remoteStatus && value['connect_status']['patient']) {
            setTimeout(async () => {
              try {
                await this.agoraService.initialize();
              } catch (error) {
                console.log('Arama hatası:', error);
                this.finishCall();
              }
            }, 1000);
          }
        })
      ).subscribe();
    }

    this.time.interval = setInterval(() => {
      this.time.count += 1;
      const dateObj = new Date(this.time.count * 1000);
      const hours = dateObj.getUTCHours();
      const minutes = dateObj.getUTCMinutes();
      const seconds = dateObj.getSeconds();

      const timeString = hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds.toString().padStart(2, '0');

      this.time.text = timeString;
      // this.cd.detectChanges();
    }, 1000);
  }
  
  ngOnDestroy() {
    this.finishCall();

    if (this.threadSub) {
      this.threadSub.unsubscribe();
    }
  }

  public finishCall() {
    this.agoraService.closeChannel(this.threadID);
    this.threadService.setActiveThread(null);
    clearInterval(this.time.interval);
  }

  public changeMic() {
    if (this.micStatus) {
      this.agoraService.muteAudio();
      this.micStatus = false;
    } else {
      this.agoraService.unmuteAudio();
      this.micStatus = true;
    }
  }

  public changeAudio() {
    this.audioStatus = !this.audioStatus;
    this.remoteElement.volume = this.audioStatus
      ? 1 : 0;

    this.remoteElement.muted = this.audioStatus
      ? false : true;
  }
}
