export interface SocialMedia {
  linkedin: string;
  instagram: string;
  twitter: string;
  youtube: string;
  facebook: string;
}

export class User {
  about: string;
  address: string;
  city: string;
  school: string;
  social_media?: SocialMedia;
  appointment: {
    minutes: number;
    price: number;
    status: {
      chat: boolean;
      video: boolean;
      voice: boolean;
    }
  };
  birth_date: number;
  client: {
    last_login_ip: string;
    register_ip: string;
  };
  email: string;
  firstname: string;
  is_doctor: boolean;
  is_online: boolean;
  lastname: string;
  display_name: string;
  sub_merchant_key: string;
  phone_number: string;
  profile_image: string;
  video: string;
  video_id: string;
  questions: string[];
  tags:string[];
  pre_meeting: boolean;
  register_date: number;
  approved: boolean;
  pre_approved: boolean;
  review: {
    average_point: number;
    total_evaluation: number;
    total_point: number;
  };
  documents: {
    bank_certificate: string[];
    identification: string[];
    school_certificate: string;
  };
  bank_account: {
    iban: string;
  };
  role: 'doctor' | 'user' | 'sports_coach' | 'nutritionist' | 'psychologist';
  uid?: string;
}
