import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ThreadService } from './thread.service';
import { HttpClient } from '@angular/common/http';
import { apiURL } from '../../../environments/environment';

declare var Peer;

@Injectable({
  providedIn: 'root'
})
export class WebrtcService {
  public peer: any;
  public localStream: MediaStream;
  public localElement: HTMLMediaElement;
  public remoteElement: HTMLMediaElement;

  public infoElement: HTMLElement;
  public loadingElement: HTMLElement;

  private threadID: string;
  private isDoctor: boolean;
  private connected: boolean;
  private type: string;

  constructor(
    private fireStore: AngularFirestore,
    private threadService: ThreadService,
    private httpClient: HttpClient
  ) { }

  public getMedia() {
    navigator.getUserMedia({
      audio: true, video: this.type === 'video'
        ? true
        : false
    }, (stream) => {
      this.handleSuccess(stream);
    }, (reason) => {
      this.handleError(reason);
    });
    // .then((stream) => {
    //   this.handleSuccess(stream);
    // }).catch((reason) => {
    //   this.handleError(reason);
    // });
  }

  public async init(
    userId: string,
    localEl: any,
    remoteEl: any,
    loadingEl: HTMLElement,
    threadID: string,
    isDoctor: boolean,
    type: string
  ) {
    if (type === 'video') {
      this.localElement = localEl;
    } else {
      this.infoElement = localEl;
    }

    this.remoteElement = remoteEl;
    this.loadingElement = loadingEl;
    this.threadID = threadID;
    this.isDoctor = isDoctor;
    this.type = type;

    try {
      this.getMedia();
    } catch (e) {
      this.handleError(e);
    }
    await this.createPeer(userId, threadID);
  }

  async createPeer(userId: string, threadID: string) {
    const iceResult = await this.httpClient.get(
      apiURL + 'app/webrtc/ice'
    ).toPromise();

    console.log('Ice Response:', iceResult);

    this.peer = new Peer(userId, {
      host: 'webrtc.askipo.com',
      port: 443,
      secure: true,
      key: 'peerjs',
      debug: 0,
      config: {
        'iceServers': iceResult['content']['iceServers']
      }
    });
    this.peer.on('open', () => {
      console.log('peer sunucusuna bağlandı.');
      this.loadingElement.classList.remove('hidden');
      if (this.type === 'video') {
        this.localElement.volume = 0;
      }
      this.fireStore.collection('threads').doc(threadID)
        .update({
          [`connect_status.${this.isDoctor ? 'doctor' : 'patient'}`]: true
        });
      this.wait();
    });
  }

  call(partnerId: string) {
    const call = this.peer.call(partnerId, this.localStream);

    call.on('stream', (stream) => {
      this.loadingElement.classList.add('hidden');
      if (this.type === 'voice') {
        this.infoElement.classList.remove('hidden');
      }
      this.remoteElement.classList.remove('hidden');
      this.remoteElement.srcObject = stream;
      this.connected = true;
    });

    call.on('error', (error) => {
      console.log('call hata verdi', error);
      // this.close();
    });

    setTimeout(() => {
      if (!this.connected) {
        this.call(partnerId);
      }
    }, 5000);
  }

  wait() {
    this.peer.on('call', (call) => {
      call.answer(this.localStream);
      call.on('stream', (stream) => {
        this.loadingElement.classList.add('hidden');
        if (this.type === 'voice') {
          this.infoElement.classList.remove('hidden');
        }
        this.remoteElement.classList.remove('hidden');

        this.remoteElement.srcObject = stream;
      });
    });
  }

  close() {
    try {
      this.peer.destroy();
    } catch (error) {
      console.log('peer destroy hatası.', error);
    }

    try {
      this.localStream.getTracks().forEach((track) => {
        track.stop();
      });
    } catch (error) {
      console.log('Medya bağlantısı kesilirken hata oluştu.');
    }

    this.threadService.callChange(
      this.threadID,
      this.type,
      0
    );

    this.fireStore.collection('threads').doc(this.threadID)
      .update({
        connect_status: {
          patient: false,
          doctor: false
        }
      });
  }

  handleSuccess(stream: MediaStream) {
    this.localStream = stream;

    if (this.type === 'video') {
      this.localElement.srcObject = stream;
    }
  }

  handleError(error: any) {
    console.log('Çağrıda hata oluştu.', error);
    this.close();
  }

  public changeMicStatus() {
    this.localStream.getAudioTracks().forEach((track) => {
      track.enabled = !track.enabled;
    });
  }

  public changeVideoStatus() {
    this.localStream.getVideoTracks().forEach((track) => {
      track.enabled = !track.enabled;
    });
  }
}
