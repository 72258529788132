// import { MenusService } from './../../../../../../../core/services/menus.service';
import { Menu } from './../../../../../../../core/models/menu.model';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UserPackage, UserPackageTrack } from '@core/models/user_packages.model';
import { DietMenuService } from '@core/services/diet-menu.service';

@Component({
  selector: '[kt-client-assign-modal-item]',
  templateUrl: './client-assign-modal-item.component.html',
  styleUrls: ['./client-assign-modal-item.component.scss']
})
export class ClientAssignModalItemComponent  {
  @Input() menu: Menu;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() client: UserPackageTrack;
  @Output() assigned = new EventEmitter<boolean>();
  date: Date;

  public loading: boolean;

  constructor(private dietMenuService: DietMenuService) { }

  assign() {
    if (this.loading) {
      return false;
    }

    this.loading = true;
    this.dietMenuService.assignMenu(
      this.menu.id,
      this.client.uid,
      this.date.setHours(0, 0, 0, 0)
    ).then(() => {
      this.assigned.emit(true);
    }).catch((err) => { this.assigned.emit(false); console.log(err); })
    .then(() => this.loading = false);
    // return this.menusService.assignMenu(this.menu.id, this.client, this.date.setHours(0, 0, 0, 0)).then(() => {
    // this.assigned.emit(true);
    // }).catch(() => {
    //   this.assigned.emit(false);
    // });
  }
}
