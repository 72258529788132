import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private fAnalytics: AngularFireAnalytics) { }

  public logEvent(name: string, params: any) {
    this.fAnalytics.logEvent(name, params);
  }

  public logScreen(name: string) {
    this.fAnalytics.setCurrentScreen(name);
  }
}
