import {Appointment} from './../../../../../core/models/appointment.model';
import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import firebase from 'firebase/app';
import moment from 'moment';
import {addMinutes} from 'date-fns';
import {Thread} from '@core/models/thread.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'chat-contact',
  templateUrl: './chat-contact.component.html',
  styleUrls: ['./chat-contact.component.scss']
})
export class ChatContactComponent implements OnInit {

  @Input() chat: Thread;
  @Input() isDoctor: boolean;

  @Input() live: boolean;

  @Input() active: boolean;

  @Output() select = new EventEmitter<any>();
  @Output() profile = new EventEmitter<any>();

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    const now = firebase.firestore.Timestamp.now().toMillis();
  }

  emitSelect() {
    this.select.emit();
  }

  emitProfile() {
    this.profile.emit();
  }

  public byPass(imageUrl: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
  }
}
