import { Thread } from './../../../../../core/models/thread.model';
import { Message } from './../../../../../core/models/message.model';
import { Component, OnInit, Input } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {

  @Input() mine: boolean;
  @Input() message: Message;
  @Input() isDoctor: boolean;
  @Input() thread: Thread;

  constructor(
    private http: HttpClient
  ) {
  }

  async ngOnInit() {
    console.log(this.message);

    if (this.message.files != undefined && this.message.files.length > 0 && this.message.files[0].type === 'file') {
      this.message.files[0] = {
        ...this.message.files[0],
        fileName: decodeURI(this.message.files[0].src.replace(/^.*[\\\/]/, ''))
      };
    }
  }

  showImg(url: string) {
    Swal.fire({
      html: '<img style="width: 100%" src=' + url + '>',
      width: '30%',
      showConfirmButton: false
    });
  }

  getFileFromUrl(url: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers: headers, responseType: 'blob' }).toPromise();
  }

}
