import { UserStore } from '@core/store/user.store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { User, SocialMedia } from '../auth/_models/user.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { subMonths } from 'date-fns';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
// import {apiURL} from '@environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private fireStore: AngularFirestore,
    private fireStorage: AngularFireStorage,
    private store: UserStore,
    private http: HttpClient,
    private afAuth: AngularFireAuth
  ) {
  }

  public async saveUser(data: any) {
    const uid = this.store.getValue().user.uid;
    // TODO ui avatar yerine kendi sistemimizi yazdığımızda burdaki if conditionu değişecek.
    if (data.profile_image) {
      data.profile_image = await this.uploadAvatar(data.profile_image, uid);
      // await this.uploadAvatar(data.profile_image, uid);
    } else {
      delete data.profile_image;
    }

    const updateResult = this.updateUser(data);

    return {
      updateResult,
      image: data.profile_image
    };

  }

  public getNotifications() {
    const uid = this.store.getValue().user.uid;
    const now = firebase.firestore.Timestamp.now().toDate();
    const oneMonthAgo = +subMonths(now, 1);
    return this.fireStore.collection('notifications', query =>
      query.where('owner_id', '==', uid).orderBy('create_date', 'desc').where('create_date', '>', oneMonthAgo)
    ).valueChanges({
      idField: 'id'
    });
  }

  public readNotifications(nots: any[]) {
    const batch = this.fireStore.firestore.batch();
    const collection = this.fireStore.collection('notifications');

    nots.map((not) => {
      if (not.was_read) {
        return;
      }
      batch.update(collection.doc(not.id).ref, { was_read: true });
    });

    return batch.commit();
  }

  public async removeAccount() {
    const user = this.store.getValue().user;
    const fbUser = await this.afAuth.user.pipe(
      first()
    ).toPromise();
    const token = await fbUser.getIdToken();

    return this.http.post(environment.cloudFunctionUrl + 'app/account/remove', {
      uid: user.uid,
      ip: (await this.ipAddress)
    }, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      })
    }).toPromise().then((response) => {
      // console.log(response);
      return response['status'] !== 'failure';
    });
  }

  public async changePhoneSendCode(phone: string) {
    const user = this.store.getValue().user;
    const fbUser = await this.afAuth.user.pipe(
      first()
    ).toPromise();
    const token = await fbUser.getIdToken();

    return this.http.post(environment.cloudFunctionUrl + 'app/account/send-code', {
      uid: user.uid,
      phone
    }, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      })
    }).toPromise().then(async (response) => {
      return response;
    });
  }

  public async changePhoneConfirmCode(code: string) {
    const user = this.store.getValue().user;
    const fbUser = await this.afAuth.user.pipe(
      first()
    ).toPromise();
    const token = await fbUser.getIdToken();

    return this.http.post(environment.cloudFunctionUrl + 'app/account/confirm-code', {
      uid: user.uid,
      code
    }, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      })
    }).toPromise().then(async (response) => {
      return response;
    });
  }

  public updateUser(data: any) {
    const uid = this.store.getValue().user.uid;
    console.log('data', data);
    return this.fireStore
      .collection('users')
      .doc(uid)
      .update(data);
  }

  public getUser(uid: string): Observable<User> {
    return this.fireStore
      .collection('public_users')
      .doc(uid)
      .valueChanges() as Observable<User>;
  }

  public getUserPromise<T = unknown>(uid: string) {
    return this.fireStore
      .collection<T>('public_users')
      .doc<T>(uid)
      .ref.get();
  }

  public async uploadAvatar(file: File, uid: string) {
    const fbUser = await this.afAuth.user.pipe(
      first()
    ).toPromise();
    const token = await fbUser.getIdToken();

    const formData: FormData = new FormData();
    formData.set(`${uid}`, file);
    formData.set('uid', uid);

    const res = await this.http.post(
      environment.cloudFunctionUrl + 'app/account/upload-profile-image',
      formData,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + token,
        })
      }
    ).toPromise();

    return (res as any).url;
  }

  public async uploadVideo(url: string, uid: string) {
    const vidRef = this.fireStorage.ref(`users/${uid}.mp4`);
    const snapshot = await vidRef.putString(url, 'data_url');

    const result = await snapshot.ref.getDownloadURL();
    return result;
  }

  public saveSocialMedia(uid: string, socialDoc: SocialMedia): Promise<void> {
    return this.fireStore.collection('users').doc(uid).update({
      social_media: socialDoc,
    });
  }

  public async saveTags(uid: string, tags: string[]) {
    await this.fireStore.collection('users').doc(uid).update({
      tags: tags,
    });

    const queryResult = await this.fireStore.collection('packages').ref
      .where('author.uid', '==', uid).get();

    if (queryResult.empty) {
      return true;
    }

    return queryResult.docs[0].ref.update({
      ['author.tags']: tags,
    });
  }

  public async saveTagsForPsychologist(uid: string, tags: string[]) {
    await this.fireStore.collection('users').doc(uid).update({
      tags: tags,
    });

    const queryResult = await this.fireStore.collection('psychologist_packages').ref
      .where('author.uid', '==', uid).get();

    if (queryResult.empty) {
      return true;
    }

    return queryResult.docs[0].ref.update({
      ['author.tags']: tags,
    });
  }

  public async sendContact(data) {
    return this.fireStore.collection('contacts').add({
      ...data,
      ip: (await this.ipAddress || '')
    });
  }

  public async resetEmail(email: string) {
    const user = this.store.getValue().user;
    const fbUser = await this.afAuth.user.pipe(
      first()
    ).toPromise();
    const token = await fbUser.getIdToken();

    return this.http.post(environment.cloudFunctionUrl + 'app/account/init-validate-email', {
      ip: (await this.ipAddress),
      uid: user.uid,
      email,
    }, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
      })
    }).toPromise().then((response) => {
      return response;
    });
  }

  public async hasFutureAppointment() {
    const now = firebase.firestore.Timestamp.now().toMillis();
    const uid = this.store.getValue().user.uid;
    const ref = await this.fireStore.collection('appointments').ref
      .where('assigned_uid', '==', uid)
      .where('date', '>', now).get();

    return ref.docs.length > 0;
  }

  public get ipAddress() {
    return this.http.get(environment.ipURL).toPromise() as Promise<string>;
  }
}
