import { AuthService } from './core/auth/_services/auth.service';
import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as jpLang } from './core/_config/i18n/jp';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as frLang } from './core/_config/i18n/fr';
import Swal from 'sweetalert2';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'Askipo';
  loader: boolean;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private translationService: TranslationService,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private auth: AuthService,
    private deviceService: DeviceDetectorService
  ) {
    // register translations
    this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
  }

  ngOnInit(): void {
    // if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
    //   console.log('mobile or tablet');
    //   if (this.deviceService.getDeviceInfo().os === 'iOS') {
    //     window.location.href = 'https://apps.apple.com/tr/app/askipo/id1492793493';
    //   } else {
    //     window.location.href = 'https://play.google.com/store/apps/details?id=com.askipo.patient';
    //   }
    // }
    // SweetAlert2 çıktığında aşağıda bi boşluk kalıyor.
    // node modulesdan düzenleyebilirdim fakat iyi bi çözüm yöntemi değil.
    document.querySelector('body').setAttribute('style', 'height: 100% !important');
    this.auth.subscribeUser();
    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
