import { ClientAssignModalComponent } from './../doctor/client-list/client-assign-modal/client-assign-modal.component';
import { ClientAssignModalItemComponent } from './../doctor/client-list/client-assign-modal/client-assign-modal-item/client-assign-modal-item.component';

import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DietMenuService } from '@core/services/diet-menu.service';

@NgModule({
  declarations: [ClientAssignModalItemComponent, ClientAssignModalComponent],
  imports: [  MatDialogModule,  CommonModule,  FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule],
  entryComponents: [ClientAssignModalItemComponent, ClientAssignModalComponent],
  exports: [ClientAssignModalItemComponent, ClientAssignModalComponent],
  providers: [DietMenuService]
})
export class SharedModule {
}
