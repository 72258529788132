import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DoctorService } from './doctor.service';
import {
  connect,
  Room,
  createLocalVideoTrack,
  RemoteTrack,
  RemoteVideoTrack,
} from 'twilio-video';
import { environment } from '@environments/environment';
import { ThreadService } from './thread.service';


@Injectable()
export class TwilioService {
  private threadId: string;
  private callIsActive: boolean = false;
  public isStreamPublished: boolean = false;

  public remoteVideo: ElementRef;
  public localVideo: ElementRef;
  isLocalVideoActive: boolean;
  msgSubject = new BehaviorSubject("");
  roomObj: any;

  private localTrack;

  constructor(
    private fireStore: AngularFirestore,
    private doctorService: DoctorService,
    private httpClient: HttpClient,
    private threadService: ThreadService
  ) {
  }

  public async initialize() {
    const thread = await this.threadListener();

    thread.subscribe(async (response) => {
      if (typeof response === 'undefined') {
        this.disconnect();
        return;
      }

      if (response === 'skip') {
        return;
      }

      if (response.callIsActive) {
        const { token, uid, status } = await this.createAccessToken();
        if (!status) {
          this.disconnect(this.threadId);
          return;
        }
        this.connectToRoom(token, { name: response.threadId, audio: true, video: { width: 240 } })
      }
    });

  }

  private connectToRoom(accessToken: string, options): void {
    connect(accessToken, options).then(room => {
      this.roomObj = room;

      if (!this.isLocalVideoActive && options['video']) {
        this.startLocalVideo();
        this.isLocalVideoActive = true;
      }

      this.assignRoomHandlers(room);
      this.isStreamPublished = true;
    });
  }

  public disconnect(threadId: string = 'none') {
    if (threadId !== 'none') {
      this.fireStore
        .collection('threads')
        .doc(threadId)
        .update({
          ['call.video']: 0,
        });
    }
    if (this.roomObj && this.roomObj !== null) {
      this.roomObj.disconnect();
      this.roomObj = null;
    }
    this.isStreamPublished = false;
    this.callIsActive = false;
    this.isLocalVideoActive = false;
  }

  private assignRoomHandlers(room: Room) {
    room.on('participantConnected', (participant) => {
      this.msgSubject.next("Already in Room: '" + participant.identity + "'");
      // this.attachParticipantTracks(participant);
      participant.tracks.forEach(pub => {
        pub.on('subscribed', (track: RemoteTrack) => {
          if ((track as RemoteVideoTrack).attach !== undefined) {
            this.remoteVideo.nativeElement.appendChild((track as RemoteVideoTrack).attach());
          }
        });
        pub.on('unsubscribed', (track: RemoteTrack) => {
          (track as RemoteVideoTrack).detach().forEach(el => el.remove());
        });
      });
    });

    room.on('participantDisconnected', (participant) => {
      this.msgSubject.next("Participant '" + participant.identity + "' left the room");
      this.detachParticipantTracks(participant);
    });

    room.on('trackSubscribed', (track, participant) => {
      this.msgSubject.next(participant.trackName + " added track: " + track.kind);
      this.attachTracks([track]);
    });

    room.on('trackUnsubscribed', (track, participant) => {
      this.msgSubject.next(participant.trackName + " removed track: " + track.kind);
      this.detachTracks([track]);
    });

    room.on('disconnected', room => {
      this.msgSubject.next('You left the Room:' + room.name);
      this.localTrack.detach().forEach(function (detachedElement) {
        detachedElement.remove();
      });
      this.localTrack.stop();
      this.threadService.setActiveThread(null);
    });
  }

  private async createAccessToken() {
    const user = this.doctorService.currentUser();
    const URL = environment.apiURL + 'app/webrtc/generate-token';

    return this.httpClient
      .post(URL, {
        username: user.uid || new Date().getTime().toString()
      })
      .toPromise()
      .then((result: any) => {
        return { status: true, token: result.token, uid: user.uid };
      }).catch((reason) => {
        // try { this.saveLog(JSON.stringify(reason)) } catch (error) { }
        // this.closeCall();
        console.error(reason);
        return { status: false, token: 'none', uid: user.uid };
      });
  }

  private attachParticipantTracks(participant): void {
    var tracks = Array.from(participant.tracks.values());
    this.attachTracks([tracks]);
  }

  private attachTracks(tracks) {
    tracks.forEach(track => {
      this.remoteVideo.nativeElement.appendChild(track.attach());
    });
  }

  private startLocalVideo(): void {
    createLocalVideoTrack().then(track => {
      this.localVideo.nativeElement.appendChild(track.attach());
      this.localTrack = track;
    });
  }

  private detachParticipantTracks(participant) {
    var tracks = Array.from(participant.tracks.values());
    this.detachTracks(tracks as any[]);
  }

  private detachTracks(tracks): void {
    tracks.forEach(function (track) {
      track.detach().forEach(function (detachedElement) {
        detachedElement.remove();
      });
    });
  }

  private async threadListener(): Promise<Observable<any>> {
    const user = this.doctorService.currentUser();

    return this.fireStore
      .collection('threads', (query) =>
        query
          .where('doctor.uid', '==', user.uid)
          .where('call.video', '>=', 1)
          .limit(1)
      )
      .valueChanges({ idField: 'id' })
      .pipe(
        map((value) => {
          if (value.length === 0) {
            return;
          }

          const thread = value[0] as any;
          this.threadId = thread.id;
          const callIsActive = thread.call.video === 2;

          if (this.callIsActive && callIsActive) {
            return 'skip';
          }

          this.callIsActive = callIsActive;
          const userDetail = {
            uid: thread.patient.uid,
            image: thread.patient.profile_image,
            name: thread.patient.display_name,
          };

          return { callIsActive, userDetail, threadId: thread.id };
        })
      );
  }

  public toggleAudio(micStatus: boolean) {
    if (this.roomObj) {
      this.roomObj.localParticipant.audioTracks.forEach(track => {
        if (micStatus) {
          track.track.disable();
        } else {
          track.track.enable();
        }
      });
    }
  }

  public toggleVideo(videoStatus: boolean) {
    if (this.roomObj) {
      this.roomObj.localParticipant.videoTracks.forEach(track => {
        if (videoStatus) {
          track.track.disable();
          this.localTrack.detach().forEach(function (detachedElement) {
            detachedElement.remove();
          });
          this.localTrack.stop();
        } else {
          track.track.enable();
          this.startLocalVideo();
        }
      });
    }
  }
}
