import {UserQuery} from './../../../core/query/user.query';
import {interval, Observable} from 'rxjs';
// Angular
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';
// Object-Path
import * as objectPath from 'object-path';
// Loading bar
import {LoadingBarService} from '@ngx-loading-bar/core';
// Layout
import {LayoutConfigService, LayoutRefService} from '../../../core/_base/layout';
// HTML Class Service
import {HtmlClassService} from '../html-class.service';
import {UserService} from '@core/services/user.service';
import firebase from 'firebase/app';
import moment from 'moment';
import {filter, map, startWith} from 'rxjs/operators';
import {addMinutes, addSeconds} from 'date-fns';
import { DietProgramService } from '@core/services/diet-program.service';
import { DietProgram } from '@core/models/diet-program/diet-program.model';

@Component({
  selector: 'kt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  // Public properties
  menuHeaderDisplay: boolean;
  fluid: boolean;

  name$: Observable<string>;

  url: string;

  toggleNotifications = false;

  notifications: Observable<any[]>;

  now;

  isDoctor$: Observable<boolean>;

  private clock$: Observable<Date>;

  dietProgram:DietProgram;
  dietProgramUrl:string;

  @ViewChild('ktHeader', {static: true}) ktHeader: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private layoutRefService: LayoutRefService,
    private layoutConfigService: LayoutConfigService,
    private query: UserQuery,
    public loader: LoadingBarService,
    public htmlClassService: HtmlClassService,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) {

    this.url = this.headerText(this.router.url);

    this.isDoctor$ = this.query.is_doctor$;
    // page progress bar percentage
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // set page progress bar loading to end on NavigationEnd event router
        this.loader.complete();
        this.url = this.headerText(event.url);
      }
    });
  }

  headerText(path: string) {
    if (path.includes('chat')) {
      return 'Sohbet';
    } else if (path.includes('purchase-package')) {
      return 'Ödeme';
    } else if (path.includes('notifications')) {
      return 'Bildirimler';
    } else if (path.includes('doctor-det')) {
      return 'Diyetisyen Profili';
    } else if (path.includes('doctor-det')) {
      return 'Diyetisyen Profili';
    } else if (path.includes('past-meetings')) {
      return 'Geçmiş Görüşmeler';
    } else if (path.includes('meetings')) {
      return 'Görüşmeler';
    } else if (path.includes('client-list')) {
      return 'Danışan Takip';
    } else if (path.includes('recipes')) {
      return 'Tarifler';
    } else if (path.includes('mymenus')) {
      return 'Menüm';
    } else if (path.includes('menus')) {
      return 'Menüler';
    } else if (path.includes('appointment-detail')) {
      return 'Görüşme Detayı';
    } else if (path.includes('appointments')) {
      return 'Müsait Randevu Saatleri';
    } else if (path.includes('client-profile')) {
      return 'Danışan Profili';
    } else if (path.includes('comments')) {
      return 'Yorumlar';
    } else if (path.includes('profile/view') || path.includes('profile/myprofile')) {
      return 'Profil';
    } else if (path.includes('feedback')) {
      return 'Geri Bildirim';
    } else if (path.includes('profile/edit') || path.includes('settings')) {
      return 'Ayarlar';
    } else if (path.includes('diet-program')) {
      return 'Diyet Programları';
    } else {
      return 'welcome';
    }
  }

  time(now) {
    const format = 'hh:mm:ss';
    const time = moment(now, format);

    const morning = moment('02:00:00', format);
    const day = moment('12:00:00', format);
    const night = moment('19:00:00', format);

    if (time.isBetween(morning, day)) {
      return 'Günaydın';
    }
    if (time.isBetween(day, night)) {
      return 'İyi günler';
    }
    return 'İyi akşamlar';


  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit():void {
    this.startTimer();
    const config = this.layoutConfigService.getConfig();

    this.now = firebase.firestore.Timestamp.now().toDate();

    this.clock$ = interval(1000).pipe(
      startWith(this.now),
      map((tick) => this.now = addSeconds(this.now, 1))
    );

    // get menu header display option
    this.menuHeaderDisplay = objectPath.get(config, 'header.menu.self.display');

    // header width fluid
    this.fluid = objectPath.get(config, 'header.self.width') === 'fluid';

    this.name$ = this.query.name$;

    this.notifications = this.userService.getNotifications();
    // animate the header minimize the height on scroll down. to be removed, not applicable for default demo
    /*if (objectPath.get(config, 'header.self.fixed.desktop.enabled') || objectPath.get(config, 'header.self.fixed.desktop')) {
        // header minimize on scroll down
        this.ktHeader.nativeElement.setAttribute('data-ktheader-minimize', '1');
      }*/

    setInterval(()=>{
      if(this.url === 'Diyet Programları'){
        var dietProgram = window.localStorage.getItem('diet_program');
        this.dietProgramUrl = window.location.href.split('/')[4];

        if(dietProgram == 'undefined'){
          dietProgram = undefined
        }

        if(JSON.stringify(this.dietProgram) !== dietProgram){
          console.log('Diyet Programları')
          if(dietProgram){
            this.dietProgram = JSON.parse(dietProgram);
          }else{
            this.dietProgram = undefined;
          }
          this.cd.detectChanges();
        }
      }
    },700);
  }

  ngAfterViewInit(): void {
    // keep header element in the service
    this.layoutRefService.addElement('header', this.ktHeader.nativeElement);
  }

  goPage(url:string){
    const programId = window.location.href.split('/')[5];
    this.router.navigate(['diet-program/'+url,programId]);
  }

  interval;
  async startTimer() {
    /*this.interval = setInterval(async i => {
      this.programIsEnabled = await this.dietProgramService.clickedIsEnabled();
      console.log(this.programIsEnabled);
    },500);*/
  }
}
