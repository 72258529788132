import {User} from './../auth/_models/user.model';
import {Store, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {Appointment} from '@core/models/appointment.model';
import {UserPackage, UserPackageTrack} from '@core/models/user_packages.model';
import {Package} from '@core/models/package.model';
import { DietProgram } from '@core/models/diet-program/diet-program.model';
import { PsychologistPackage } from '@core/models/psychologist-package';

export interface UserState {
  user: User;

  package: UserPackageTrack;

  doctorPackage: Package;

  psychologistPackage: PsychologistPackage;

  merchant: any;

  appointment: Appointment; // geçişlerde kullanılıcak
  data: any;
}

export function createInitialState() {
  const empty = null;
  return {
    user: {
      firstname: '',
      lastname: '',
      ...empty
    },
    package: null,
    appointment: null,
    // merchant: {
    //   iban: '',
    //   contactName: '',
    //   contactSurname: '',
    // },
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'user'})
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }
}
