import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, PipeTransform } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { User } from '@core/auth/_models/user.model';
import { Appointment } from '@core/models/appointment.model';
import { DoctorService } from '@core/services/doctor.service';
import { ThreadService } from '@core/services/thread.service';
import { UserStore } from '@core/store/user.store';
import { ClientAssignModalComponent } from '@pages/content/doctor/client-list/client-assign-modal/client-assign-modal.component';
import { addMinutes } from 'date-fns';
import firebase from 'firebase';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-meetings-list',
  templateUrl: './meetings-list.component.html',
  styleUrls: ['./meetings-list.component.scss']
})
export class MeetingsListComponent implements OnInit, OnDestroy {

  subscribers: any;

  meetings: any;

  public appointments: any[];
  public filteredAppointments: any[];

  subscription: Subscription;

  status = 0;

  isDoctor: boolean;

  users: User[] = [];

  public nowTime: number;
  public interval: any;

  is_psychologist: boolean = false;

  @Input() pageSize: string = '20';

  public activePages = [1];
  public filterForm: FormGroup;

  constructor(
    private store: UserStore,
    private cd: ChangeDetectorRef,
    private doctorService: DoctorService,
    private router: Router,
    private pipe: DecimalPipe,
    private dialog: MatDialog,
    private threadService: ThreadService,
  ) {
    this.is_psychologist = this.store.getValue().user.role == "psychologist";
  }

  async ngOnInit() {
    this.filterForm = new FormGroup({
      search: new FormControl(''),
      page: new FormControl(0),
      pageSize: new FormControl(Number(this.pageSize)),
      colSize: new FormControl(25),
    });

    this.isDoctor = this.store.getValue().user.is_doctor;

    this.nowTime = firebase.firestore.Timestamp.now().toMillis();

    this.interval = setInterval(() => {
      this.nowTime = firebase.firestore.Timestamp.now().toMillis();
      try {
        this.cd.markForCheck();
      } catch (error) {
        console.log('mark for check', error);
      }
    }, 1000);

    this.appointments = await this.doctorService.getAppointmentsWithUsers();
    this.filteredAppointments = _.cloneDeep(this.appointments);
    this.filterForm.valueChanges.subscribe(() => {
      if (this.filterForm.value.search.length !== 0) {
        this.filteredAppointments = this.search(this.appointments, this.filterForm.value, this.pipe);
        this.cd.markForCheck();
      }
    });

    this.filterForm.controls.colSize.setValue(this.appointments.length);

    this.cd.markForCheck();

    this.filterForm.controls.page.valueChanges.subscribe((async (value) => {
      if (this.activePages.indexOf(value) === -1) {
        this.activePages.push(value);

        this.cd.markForCheck();

        const newData = await this.doctorService.getAppointmentsWithUsers(
          this.appointments[this.appointments.length - 1].date,
        );

        newData.forEach((item) => {
          this.appointments.push(item);
        });

        this.filteredAppointments = _.cloneDeep(this.appointments);

        this.filterForm.controls.colSize.setValue(this.appointments.length);

        this.cd.markForCheck();
      }
    }));
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  join(threadID: string, type: string) {
    this.router.navigate(['/chat/' + threadID + '/' + type]);
  }

  showProfile(id: string) {
    if (this.isDoctor) {
      this.router.navigate(['client-profile/' + id]);
      return;
    }
  }

  search(text: any, value: any[], pipe: PipeTransform) {
    return value.filter(val => {
      const term = this.filterForm.value.search.toLowerCase();
      return (val.user.is_doctor ? val.user.firstname + ' ' + val.user.lastname : val.user.display_name)
        .toLowerCase().includes(term);
    }).slice(
      (this.filterForm.value.page - 1) * this.filterForm.value.size,
      (this.filterForm.value.page - 1) * this.filterForm.value.size + this.filterForm.value.size
    );
  }

  doctorDetail(id: string) {
    this.router.navigate(['doctor-details/' + id]);
  }

  navigateMenu(uid: string) {
    this.router.navigate(['diet-menu/client/', uid]);
  }
  //
  appointmentDetail(appointment: Appointment) {
    this.store.update(() => ({
      appointment
    }));
    // console.log('updated', appointment);
    this.router.navigate(['appointment-detail']);
  }

  async menuAssign(appoinment: Appointment) {
    console.log(appoinment.assigned_uid);
    const client = await this.doctorService.getUserPackage(appoinment.assigned_uid);
    if (!client) {
      Swal.fire({
        title: `Uyarı!`,
        text: 'Bu kullanıcının paketi olmadığı için menü atayamazsın.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Tamam',
      });
      return;
    }
    this.dialog.open(ClientAssignModalComponent, {
      width: '700px',
      data: { client },
    });
  }

  public sendCall(appointment: Appointment, type: string) {
    this.threadService.sendCall(appointment.thread_id, type).then(res => console.log('sentcall:', res));
    this.threadService.thread(appointment.thread_id).pipe(first())
      .subscribe(data => {
        data.id = appointment.thread_id;
        this.threadService.setActiveThread(data);
      });
  }

}
