import {MatDialog} from '@angular/material';
import {UserStore} from '@core/store/user.store';
import {Subscription} from 'rxjs';
import {Appointment} from '@core/models/appointment.model';
import {DoctorService} from '@core/services/doctor.service';
import {Component, OnInit, ChangeDetectorRef, PipeTransform, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {ClientAssignModalComponent} from '@pages/content/doctor/client-list/client-assign-modal/client-assign-modal.component';
import {User} from '@core/auth/_models/user.model';
import Swal from 'sweetalert2';
import * as firebase from 'firebase/app';
import _ from 'lodash';

@Component({
  selector: 'kt-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss'],
  providers: [DecimalPipe]
})

export class MeetingsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
