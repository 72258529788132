import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ThreadService } from '../../../../core/services/thread.service';
import { map } from 'rxjs/operators';
import { UserStore } from '@core/store/user.store';
import { TwilioService } from '@core/services/twilio.service';

interface CustomVideoElement extends HTMLVideoElement {
  requestPictureInPicture(): any;
  exitPictureInPicture(): void;
  pictureInPictureElement: any;
}

@Component({
  selector: 'kt-webrtc',
  templateUrl: './webrtc.component.html',
  styleUrls: ['./webrtc.component.scss']
})
export class WebrtcComponent implements OnInit {
  @Input()
  public threadID: string;

  @Input()
  public userUID: string;

  @Input()
  public isDoctor: boolean;

  public remoteStatus: boolean;

  public micStatus: boolean;
  public videoStatus: boolean;
  public pipStatus: boolean;

  private threadSub: Subscription;

  @ViewChild('localVideo', { static: true }) localVideo: ElementRef;
  @ViewChild('remoteVideo', { static: true }) remoteVideo: ElementRef;

  constructor(
    private threadService: ThreadService,
    private store: UserStore,
    public twilioService: TwilioService
  ) {
    this.micStatus = true;
    this.videoStatus = true;
  }

  async ngOnInit() {
    this.twilioService.localVideo = this.localVideo;
    this.twilioService.remoteVideo = this.remoteVideo;
    await this.twilioService.initialize();

    // this.twilioService.msgSubject.subscribe(msg=>{
    //   console.log('twilio:: ', msg);
    // })

    if (!this.isDoctor) {
      this.threadSub = this.threadService.thread(this.threadID).pipe(
        map((value) => {
          this.remoteStatus = value['connect_status']['doctor'];

          if (this.remoteStatus && value['connect_status']['patient']) {
            setTimeout(() => {
              try {
                // this.webrtcService.call(this.userUID);
              } catch (error) {
                console.log('Arama hatası2:', error);
                this.finishCall();
              }
            }, 1000);
          }
        })
      ).subscribe();
    }
  }

  public finishCall() {
    this.twilioService.disconnect(this.threadID);
    this.threadService.setActiveThread(null);
  }

  public changeMic() {
    this.twilioService.toggleAudio(this.micStatus);
    this.micStatus = !this.micStatus;
  }

  public changeVideo() {
    this.twilioService.toggleVideo(this.videoStatus);
    this.videoStatus = !this.videoStatus;
  }

  ngOnDestroy() {
    this.finishCall();

    if (this.threadSub) {
      this.threadSub.unsubscribe();
    }
  }

  fullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      return;
    }
    document.getElementById('rtc').requestFullscreen();
  }

  public pictureInPicture() {
    try {
      const element = (document.getElementById('remoteVideo') as CustomVideoElement);
      if (!this.pipStatus) {
        element.requestPictureInPicture();
        this.pipStatus = true;
      } else {
        (document as any).exitPictureInPicture();
        this.pipStatus = false;
      }
    } catch (reason) {
      console.log('picture in picture hata.', reason);
    }
  }
}
