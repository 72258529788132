import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {skipWhile, map, take} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserStore} from '@core/store/user.store';
import {User} from '@core/auth/_models/user.model';
import {UserQuery} from '@core/query/user.query';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private fireStore: AngularFirestore,
    private router: Router,
    private query: UserQuery
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): any {
    return this.query.user$.pipe(
      skipWhile(user => !user),
      map((user) => {
        // console.log('user:', user);
        const doctor: boolean = route.data.doctor;
        const roles: Array<string> = route.data.roles;
        if (doctor == null) {
          return true;
        }

        if (user.is_doctor === doctor) {
          if(user.is_doctor === true && roles.indexOf(user.role) === -1){
            return false;
          }
          return true;
        }


        this.router.navigate(['/dashboard']);
        return false;
      }), take(1)).toPromise();
    return this.afAuth.authState.pipe(
      skipWhile(user => !user),
      map(async (user) => {

        const doc = await this.fireStore.collection('users').doc(user.uid).ref.get();
        const doctor: string = route.data.doctor;
        if (doctor == null) {
          return true;
        }

        if (doc.data().is_doctor === doctor) {
          return true;
        }

        this.router.navigate(['/dashboard']);
      }), take(1)).toPromise();
  }

}
