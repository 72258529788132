import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DietMenu } from '@core/models/diet-menu/diet-menu.model';
import { Meal } from '@core/models/diet-menu/meal.model';
import { Week } from '@core/models/diet-menu/week.model';
import { UserPackageTrack } from '@core/models/user_packages.model';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { DoctorService } from './doctor.service';
import { UserService } from './user.service';

@Injectable()
export class DietMenuService {
  private readonly fireStore: AngularFirestore;
  private readonly doctorService: DoctorService;

  private readonly weekList = [
    {
      order: 0,
      meal_list: []
    },
    {
      order: 1,
      meal_list: []
    },
    {
      order: 2,
      meal_list: []
    },
    {
      order: 3,
      meal_list: []
    },
    {
      order: 4,
      meal_list: []
    },
    {
      order: 5,
      meal_list: []
    },
    {
      order: 6,
      meal_list: []
    }
  ];

  constructor(fireStore: AngularFirestore, doctorService: DoctorService, private userService: UserService) {
    this.fireStore = fireStore;
    this.doctorService = doctorService;
  }

  public get menus() {
    return this.fireStore.collection('diet_menus', (query) =>
      query.where('author_uid', '==', this.doctorService.getUID())
        .where('is_deleted', '==', false)
        .orderBy('modify_date', 'desc')
    ).valueChanges({ idField: 'id' });
  }

  /**
   * Returns paginated results of diet menus.
   */
  public async getMenus(
    date: number | string = 'none',
    limit = 25
  ): Promise<DietMenu[]> {
    let query = this.fireStore.collection('diet_menus')
      .ref
      .where('author_uid', '==', this.doctorService.getUID())
      .where('is_deleted', '==', false)
      .orderBy('modify_date', 'desc');

    if (date !== 'none') {
      query = query.startAfter(date);
    }

    query = query.limit(limit);

    return query.get().then((snapshot) => {
      return snapshot.docs.map((snap) => {
        return {
          id: snap.id,
          ...snap.data()
        } as any;
      });
    });
  }

  /**
   * Sets the is_deleted field of the diet menu to true
   * @param id Id of the diet menu
   * @return Promise
   */
  public deleteDietMenu(id: string) {
    return this.fireStore.collection('diet_menus').doc(id)
      .update({ is_deleted: true });
  }

  public async getAssignClients() {
    const uid = this.doctorService.getUID();
    const today = firebase.firestore.Timestamp.now()
      .toDate()
      .setHours(0, 0, 0, 0);

    const result = await this.fireStore.collection('package_tracking', (query) => query.where('pkg_author_id', '==', uid)
      .where(
        'end_date',
        '>=',
        today
      )).snapshotChanges();

    return result;
  }

  public async getUserById(id: any) {
    const docRef = await this.userService.getUserPromise(id);
    return docRef.data();
  }

  public async getClientMenu(uid: string) {
    // const pkg = await this.havePackage(uid);
    // const startDate = moment(pkg.data['pkg_start_date']).startOf('days').toDate().getTime();

    const result = await this.fireStore.collection('users')
      .doc(uid).collection('diet_records')
      .ref.orderBy('date', 'asc')
      // .where('date', '>=', startDate)
      .get();

    return result.docs.map((value) => {
      return {
        id: value.id,
        ...value.data()
      }
    });
  }

  public async getClients() {
    const activeThreads = await this.fireStore.collection('threads').ref
      .where('doctor.uid', '==', this.doctorService.getUID())
      .where('status', '==', 1)
      .get();


    const resultPromise = await Promise.all(activeThreads.docs.map(async (snap) => {
      const packageResult = await this.havePackage(snap.get('patient.uid')) as any;

      if (packageResult.status) {
        const assigned_user = await this.doctorService.getPatientDetail(packageResult.data.uid);
        return { status: true, assigned_user, ...packageResult.data };
      } else {
        return { status: false };
      }
    }));

    return resultPromise.filter((query) => query['status'] === true && query['assigned_user']['display_name'] != 'Anonim');
  }

  private async havePackage(patientUID: string) {
    const searchResult = await this.fireStore.collection('package_tracking').ref
      .where('pkg_author_id', '==', this.doctorService.getUID())
      .where('uid', '==', patientUID)
      .where('enabled', '==', true)
      .get();

    if (searchResult.empty) {
      return { status: false, data: {} };
    }

    if (!searchResult.docs[0].get('enabled')) {
      return { status: false, data: {} };
    }

    const data = {
      id: searchResult.docs[0].id,
      ...searchResult.docs[0].data(),
    };

    return { status: true, data };
  }

  public menuDetail(id: string) {
    return this.fireStore.collection('diet_menus').doc(id).ref.get()
      .then((response) => {
        return {
          ...response.data(),
          id: response.id
        };
      });
  }

  public async cloneMenu(id: string) {
    const menuDetail = await this.menuDetail(id);
    const result = await this.fireStore.collection<DietMenu>('diet_menus').add({
      author_uid: this.doctorService.getUID(),
      create_date: firebase.firestore.Timestamp.now().toMillis(),
      modify_date: firebase.firestore.Timestamp.now().toMillis(),
      name: menuDetail['name'] + ' - Klon',
      create_ip: await this.doctorService.ipAddress,
      clients: [],
      week_list: menuDetail['week_list'],
      is_deleted: false
    });

    return result.id;
  }

  public deleteMenu(id: string) {
    return this.fireStore.collection('diet_menus').doc(id).delete();
  }

  public async createMenu(name: string) {
    const data: DietMenu = {
      author_uid: this.doctorService.getUID(),
      create_date: firebase.firestore.Timestamp.now().toMillis(),
      modify_date: firebase.firestore.Timestamp.now().toMillis(),
      name: name,
      create_ip: await this.doctorService.ipAddress,
      clients: [],
      week_list: [
        {
          order: 0,
          day_list: this.weekList
        }
      ],
      is_deleted: false
    };

    const result = await this.fireStore.collection('diet_menus').add(data);
    return result.id;
  }

  public async updateMenu(data: DietMenu) {
    const id = data.id;
    delete data['id'];

    return this.fireStore.collection('diet_menus').doc(id).update({
      ...data,
      modify_date: firebase.firestore.Timestamp.now().toMillis(),
    });
  }

  public async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  public async assignMenu(menuId: string, userId: string, startDate: number) {
    const menuDetail = await this.menuDetail(menuId);
    const recordRef = this.fireStore.collection('users')
      .doc(userId).collection('diet_records');

    let dayDate = new Date(startDate).setHours(0, 0, 0, 0);
    const weekList: Week[] = menuDetail['week_list'];
    const records = [];

    await this.asyncForEach(weekList, async (week) => {
      await this.asyncForEach(week.day_list, async (day) => {
        day['meal_list'] = await Promise.all(day['meal_list'].map(async (meal) => {
          const list: any = await Promise.all(meal.list.map(async (listItem) => {
            const recipeId = typeof listItem['recipe_id'] === 'undefined'
              ? 'none'
              : listItem['recipe_id'];

            if (recipeId === 'none') {
              return {
                ...listItem,
                type: 'menu'
              };
            } else {
              const recipeDoc = await this.fireStore.collection('recipes').doc(recipeId).ref.get();

              return {
                ...listItem,
                type: 'menu',
                recipe_detail: recipeDoc.data()
              };
            }


          }));

          return {
            ...meal,
            list: list
          };
        }));

        records.push({
          date: dayDate,
          meal_list: day.meal_list,
          menu_id: menuId
        });

        dayDate = moment(dayDate).add(1, 'days').toDate().getTime();
        // day['meal_list'] = day['meal_list'].map((meal) => {
        //   const list: any = meal.list.map((listItem) => {
        //     return {
        //       ...listItem,
        //       type: 'menu'
        //     };
        //   });

        //   return {
        //     ...meal,
        //     list: list
        //   };
        // });
      });
    });

    // weekList.forEach((week) => {
    //   week.day_list.forEach((day) => {
    //     day['meal_list'] = day['meal_list'].map((meal) => {
    //       const list: any = meal.list.map((listItem) => {
    //         return {
    //           ...listItem,
    //           type: 'menu'
    //         };
    //       });

    //       return {
    //         ...meal,
    //         list: list
    //       };
    //     });

    //     records.push({
    //       date: dayDate,
    //       meal_list: day.meal_list,
    //       menu_id: menuId
    //     });

    //     dayDate = moment(dayDate).add(1, 'days').toDate().getTime();
    //   });
    // });

    console.log('RECORDS', records);

    await Promise.all(await records.map(async (record) => {
      const haveDate = await recordRef.ref.where('date', '==', record.date).get();
      if (!haveDate.empty) {
        await haveDate.docs[0].ref.delete();
      }

      await recordRef.add(record);
    }));

    const idx = menuDetail['clients'].indexOf(userId);
    if (idx === -1) {
      await this.fireStore.collection('diet_menus').doc(menuDetail['id']).update({
        clients: firebase.firestore.FieldValue.arrayUnion(userId)
      });
    }

    const pkgRef = await this.fireStore.collection('package_tracking').ref
      .where('uid', '==', userId)
      .where('pkg_author_id', '==', this.doctorService.getUID())
      .where('enabled', '==', true)
      .get();

    console.log(pkgRef.docs[0].data());

    if (pkgRef.empty) {
      return Promise.resolve();
    }

    return pkgRef.docs[0].ref.update({
      menu_assigned: true,
      assigned_menu_last_date: records[records.length - 1]['date']
    });
  }

  public async updateClientMenu(uid: string, date: number, mealList: Meal[]) {
    const queryResult = await this.fireStore.collection('users')
      .doc(uid)
      .collection('diet_records')
      .ref.where('date', '==', date)
      .get();

    if (queryResult.empty) {
      return true;
      // return this.fireStore.collection('users').doc(uid)
      //   .collection('diet_records').add({
      //     date: date,
      //     menu_id: 'extra',
      //     meal_list: mealList
      //   });
    }

    const doc = queryResult.docs[0].ref;
    return doc.update({ meal_list: mealList });
  }

  public async addWeekClientMenu(uid: string, date: number) {
    const queryResult = await this.fireStore.collection('users')
      .doc(uid)
      .collection('diet_records')
      .ref.where('date', '==', date)
      .get();

    if (queryResult.empty) {
      return false;
    }

  }
}
