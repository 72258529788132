import { Thread } from './../../../core/models/thread.model';
// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// RxJS
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfig } from '../../../core/_config/layout.config';
import { MenuConfig } from '../../../core/_config/menu.config';
import { PageConfig } from '../../../core/_config/page.config';
// User permissions
import { TokboxService } from '../../../core/services/tokbox.service';
import { ThreadService } from '../../../core/services/thread.service';
import { UserStore } from '@core/store/user.store';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'kt-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
  // Public variables
  selfLayout: string;
  asideDisplay: boolean;
  asideSecondary: boolean;
  subheaderDisplay: boolean;
  desktopHeaderDisplay: boolean;
  fitTop: boolean;
  fluid: boolean;

  thread: Thread;

  openTok: BehaviorSubject<boolean>;

  callingThread: Thread;
  uid: string;
  isDoctor: boolean;

  private unsubscribe: Subscription[] = [];

  public initPosition = {
    x: (window.innerWidth / 2) - (380 / 2),
    y: (window.innerHeight / 2) - (460 / 2)
  };

  constructor(
    private layoutConfigService: LayoutConfigService,
    private menuConfigService: MenuConfigService,
    private pageConfigService: PageConfigService,
    private htmlClassService: HtmlClassService,
    private threadService: ThreadService,
    private tokService: TokboxService,
    private store: UserStore,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    // register configs by demos
    this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
    this.menuConfigService.loadConfigs(new MenuConfig().configs);
    this.pageConfigService.loadConfigs(new PageConfig().configs);

    // setup element classess
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
      // reset body class based on global and page level layout config, refer to html-class.service.ts
      document.body.className = '';
      this.htmlClassService.setConfig(layoutConfig);
    });
    this.unsubscribe.push(subscr);
  }

  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();
    this.selfLayout = objectPath.get(config, 'self.layout');
    this.asideDisplay = objectPath.get(config, 'aside.self.display');
    this.subheaderDisplay = objectPath.get(config, 'subheader.display');
    this.desktopHeaderDisplay = objectPath.get(config, 'header.self.fixed.desktop');
    this.fitTop = objectPath.get(config, 'content.fit-top');
    this.fluid = objectPath.get(config, 'content.width') === 'fluid';

    // let the layout type change
    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
      setTimeout(() => {
        this.selfLayout = objectPath.get(cfg, 'self.layout');
      });
    });
    this.unsubscribe.push(subscr);

    this.isDoctor = this.store.getValue().user.is_doctor;
    this.uid = this.store.getValue().user.uid;

    this.threadService.getActiveThread()
      .subscribe(res => {
        this.thread = res.filter((thread: any) => thread.call.video >= 1 || thread.call.voice >= 1)[0] as any;
        this.cdr.detectChanges();
      });

    // const video = this.threadService.callingVideo();
    // const voice = this.threadService.callingVoice();

    // combineLatest([video, voice]).subscribe(([vid, voi]) => {
    //   if (vid.length > 0) {
    //     this.callingThread = vid[0];
    //   } else if (voi.length > 0) {
    //     this.callingThread = voi[0];
    //   } else {
    //     this.callingThread = null;
    //   }

    //   try {
    //     this.cdr.markForCheck();
    //   } catch (error) {

    //   }
    // });
  }

  public activeChat() {
    return this.router.url.includes('chat');
  }

  acceptCall() {
    const type = this.callingThread.call.voice === 1 ? 'voice' : 'video';
    this.router.navigate(['chat/' + this.callingThread.id + '/' + type]);
  }

  rejectCall() {
    const type = this.callingThread.call.voice === 1 ? 'voice' : 'video';
    this.threadService.callChange(this.callingThread.id, type, 0);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

}
