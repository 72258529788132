import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@environments/environment';
import { AgoraClient, ClientEvent, NgxAgoraService, Stream, StreamEvent } from 'ngx-agora';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DoctorService } from './doctor.service';

@Injectable({
  providedIn: 'root'
})
export class AgoraService {
  private agoraClient: AgoraClient;
  public localStream: Stream = null;
  public audio: boolean = false;
  public video: boolean = false;

  private threadId: string;
  private callIsActive: boolean;
  public isStreamPublished: boolean = false;

  constructor(
    private ngxAgoraService: NgxAgoraService,
    private fireStore: AngularFirestore,
    private httpClient: HttpClient,
    private doctorService: DoctorService,
  ) {}

  public async initialize(): Promise<void> {
    const thread = await this.threadListener();

    thread.subscribe((response) => {
      if (typeof response === 'undefined') {
        this.closeChannel();
        return;
      }

      if (response === 'skip') {
        return;
      }

      if (response.callIsActive) {
        this.createEngine(response.threadId, response.userDetail);
      } else {
        // this.showCallModal(
        //   response.userDetail.name,
        //   response.userDetail.image,
        //   response.threadId
        // );
      }
    });
  }

  private async createEngine(threadId: string, userDetail: any): Promise<void> {
    const permValidate = await this.permissionValidate(threadId);

    if (!permValidate) {
      return;
    }

    this.agoraClient = this.ngxAgoraService.createClient({ codec: "h264", mode: "rtc" });
    this.assignClientHandlers(this.agoraClient, 
      evt => {
      console.log('Publish local stream successfully');
      },
      evt => {
        const stream = evt.stream as Stream;
        if (stream) {
          stream.stop();
          console.log(`${evt.uid} left from this channel`);
        }
      });
    this.agoraClient.init(environment.agoraAppId, 
      ()=>{
        // this.saveLog('Agora initialization successful');
        this.joinChannel(this.threadId);
      }, 
      (err: Error)=>{
        // this.saveLog('Agora initialization error');
        this.closeChannel(this.threadId);
      });
  }

  private assignClientHandlers(client: AgoraClient, onPublishSuccess?: (evt: any) => void, onPeerLeave?: (evt: any) => void): void {
    client.on(ClientEvent.LocalStreamPublished, onPublishSuccess);

    client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      client.subscribe(stream, { audio: this.audio, video: this.video }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    client.on(ClientEvent.RemoteStreamSubscribed, async evt => {
      const stream = evt.stream as Stream;
      setTimeout(() => { 
        stream.play('agora_remote')
      }, 1000);
    });

    client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    client.on(ClientEvent.PeerLeave, onPeerLeave);
  }

  private async permissionValidate(threadId: string) {
    const result = await this.doctorService.requestPermissionVoice();

    if (!result) {
      this.saveLog('PERMISSION_REQUIRED');

      alert('Danışanınla görüşmeni gerçekleştirebilmen için uygulamamızın mikrofonuna erişmesine izin vermen gerekmektedir.');
      this.closeChannel(threadId);
      return false;
    } else {
      return true;
    }
  }

  private async joinChannel(threadId: string) {
    await this.startLocalStream();

    const { token, uid, status } = await this.createAccessToken(threadId);

    if (!status) {
      this.closeChannel(threadId);
      return;
    }

    this.agoraClient.join(token, threadId, uid);

    setTimeout(() => {
      this.agoraClient.publish(this.localStream, err => console.log('Publish local stream error: ' + err));
      this.isStreamPublished = true;
    }, 5000);
  }

  private async createAccessToken(channelId: string) {
    const user = this.doctorService.currentUser();
    const URL = environment.apiURL + 'app/webrtc/agora-create-token';

    return this.httpClient
      .post(URL, {
        threadId: channelId,
        uid: user.uid,
      })
      .toPromise()
      .then((response: any) => {
        return { status: true, token: response.token, uid: user.uid };
      })
      .catch((reason) => {
        console.error(reason);
        // this.doctorService.toast(
        //   'Görüşmeye katılırken bir hata oluştu. Lütfen tekrar dene.'
        // );

        return { status: false, token: 'none', uid: user.uid };
      });
  }

  public closeChannel(threadId: string = 'none'): void {
    if (threadId !== 'none') {
      this.fireStore
        .collection('threads')
        .doc(threadId)
        .update({
          ['call.voice']: 0,
        });
    }
    if(this.agoraClient){
      this.agoraClient.leave(
        () => { console.log('Left the channel successfully') },
        err => { console.log('Leave channel failed') }
      );
    }
    this.isStreamPublished = false;
    this.callIsActive = false;
    if (this.localStream != null && this.agoraClient) {
      this.agoraClient.unpublish(this.localStream, error => console.error(error));
      this.localStream.stop();
      this.localStream.close();
      this.localStream = null;
    }
  }

  private async saveLog(data: string): Promise<any> {
    const user = this.doctorService.currentUser();

    try {
      return this.fireStore.collection('webrtc_logs').add({
        thread_id: this.threadId,
        uid: user.uid,
        log: data,
        date: new Date().getTime(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  private async threadListener(): Promise<Observable<any>> {
    const user = this.doctorService.currentUser();

    return this.fireStore
      .collection('threads', (query) =>
        query
          .where('doctor.uid', '==', user.uid)
          .where('call.voice', '>=', 1)
          .limit(1)
      )
      .valueChanges({ idField: 'id' })
      .pipe(
        map((value) => {
          if (value.length === 0) {
            return;
          }

          const thread = value[0] as any;
          this.threadId = thread.id;
          const callIsActive = thread.call.voice === 2;

          if (this.callIsActive && callIsActive) {
            return 'skip';
          }

          this.callIsActive = callIsActive;
          const userDetail = {
            uid: thread.patient.uid,
            image: thread.patient.profile_image,
            name: thread.patient.display_name,
          };

          return { callIsActive, userDetail, threadId: thread.id };
        })
      );
  }

  async startLocalStream(): Promise<void> {
    this.localStream = this.ngxAgoraService.createStream({ streamID: this.threadId, audio: this.audio, video: this.video, screen: false });
    this.assignLocalStreamHandlers(this.localStream);
    this.localStream.init(
      () => {
        console.log('getUserMedia successfully');
        setTimeout(() => {
          if(this.video == true){
            this.localStream.play('agora_local');
          }
        }, 500);
      },
      err => console.log('getUserMedia failed', err)
    );
  }

  private assignLocalStreamHandlers(localStream: Stream): void {
    localStream.on(StreamEvent.MediaAccessAllowed, () => {
      console.log('accessAllowed');
    });
    localStream.on(StreamEvent.MediaAccessDenied, () => {
      console.log('accessDenied');
    });
  }

  public muteAudio() {
    if (this.localStream) {
      this.localStream.muteAudio();
    }
  }

  public unmuteAudio() {
    if (this.localStream) {
      this.localStream.unmuteAudio();
    }
  }
}
