import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThreadService} from '@core/services/thread.service';
import {UserStore} from '@core/store/user.store';
import {Router} from '@angular/router';

@Component({
  selector: 'kt-profile-vertical-card',
  templateUrl: './profile-vertical-card.component.html',
  styleUrls: ['./profile-vertical-card.component.scss']
})
export class ProfileVerticalCardComponent implements OnInit {

  @Input() profileImage: any;
  @Input() name: string;
  @Input() date: number;
  @Input() uid: string;
  @Input() useCase: 'client-profile-page' | 'settings-page';

  @Input() subscriptionType: string;
  @Input() daysLeft: number;

  option: number;
  @Output() changeOption: EventEmitter<number> = new EventEmitter<number>();

  @Output() preview: EventEmitter<any> = new EventEmitter<any>();

  threadID: string;

  is_psychologist: boolean = false;

  constructor(
    private threadService: ThreadService,
    private store: UserStore,
    private router: Router
  ) {
    this.is_psychologist = this.store.getValue().user.role == "psychologist";
  }

  ngOnInit() {}

  onPreview() {
    this.preview.emit();
  }

  change(value) {
    if (this.useCase === 'client-profile-page' && value === 1) {
      this.router.navigate(['/diet-menu/client/', this.uid]);
      return;
    }

    this.option = value;
    this.changeOption.emit(value);
  }

  async chat() {
    if (!this.threadID) {
      const id = this.store.getValue().user.uid;
      const threads = await this.threadService.getThreadsByIds(id, this.uid);

      if (!threads.docs.length) {
        return;
      }

      this.threadID = threads.docs[0].id;

    }
    this.router.navigate(['/chat/' + this.threadID]);
  }

}
