export class Menu {
  name: string;
  doctor_uid: string;
  modified_date: number;
  ip_address: string;
  day_count: number;
  clients?: string[];
  id?: string;
}
export class Meal {
  foods: Food[];
  extras?: Food[];
}
export class Food {
  name: string;
  portion: string;
  description?: string;
  did_eat?: boolean;
}
export class Day {
  date: number;
  breakfast: Meal;
  afterBreakfast: Meal;
  afternoon: Meal;
  beforeEvening: Meal;
  evening: Meal;
  id?: string;
}
