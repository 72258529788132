import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MeetingsComponent } from './meetings.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import {
    NgbPaginationModule,
    NgbPagination,
    NgbButtonsModule,
    NgbDropdownModule,
    NgbTypeaheadModule,
    NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared.module';
import { MeetingsListModule } from './meetings-list/meetings-list.module';

const routes: Routes = [
  {
    path: '',
    component: MeetingsComponent
  }
]

@NgModule({
  declarations: [MeetingsComponent],
  imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      SharedModule,
      RouterModule.forChild(routes),
      NgbPaginationModule,
      NgbButtonsModule,
      NgbDropdownModule,
      NgbTypeaheadModule,
      NgbTooltipModule,
      MeetingsListModule
  ]
})
export class MeetingsModule { }
