import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MeetingsListComponent } from './meetings-list.component';
import { NgbButtonsModule, NgbDropdownModule, NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [MeetingsListComponent],
  exports: [
    MeetingsListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbButtonsModule,
    NgbDropdownModule,
    NgbTypeaheadModule,
    NgbTooltipModule,
  ],
  providers: [DecimalPipe],
})
export class MeetingsListModule { }
