import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TokboxService {

  public session: any;
  public connection: any;

  public type: string;

  public openTok: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly httpClient: HttpClient,
  ) {}

  public async initSession(threadID: string) {
    return await this.httpClient.post(environment.cloudFunctionUrl + 'tokboxThread', {
      threadID
    }).toPromise();
  }
}
