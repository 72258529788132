// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Material
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
} from '@angular/material';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
import { InterceptService } from '../../../core/_base/crud/';
// Module components
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
import { ConfirmComponent } from './confirm/confirm.component';
// Auth
import { AuthService } from '../../../core/auth';
// Angular Fire
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BrMaskerModule } from 'br-mask';
import { SelectRoleComponent } from './select-role/select-role.component';
import {NgSelect2Module} from 'ng-select2';
import { RegisterDoctorComponent } from './register-doctor/register-doctor.component';
import { SudoComponent } from './sudo/sudo.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login/diyetisyen',
        pathMatch: 'full'
      },
      {
        path: 'login/:role',
        component: LoginComponent,
        data: { returnUrl: window.location.pathname }
      },
      {
        path: 'register',
        component: RegisterDoctorComponent,
      },
      {
        path: 'register/:phone',
        component: RegisterComponent
      },
      // {
      //   path: 'role',
      //   component: SelectRoleComponent,
      // },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'confirm/:phone',
        component: ConfirmComponent
      },
      {
        path: 'sudo/:token',
        component: SudoComponent
      }
    ]
  }
];


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        RouterModule.forChild(routes),
        MatInputModule,
        MatFormFieldModule,
        MatCheckboxModule,
        TranslateModule.forChild(),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        BrMaskerModule,
        MatDatepickerModule,
        MatSelectModule,
        NgSelect2Module
    ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
  ],
  exports: [AuthComponent],
  declarations: [
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    AuthNoticeComponent,
    ConfirmComponent,
    SelectRoleComponent,
    SudoComponent,
    RegisterDoctorComponent,
  ]
})

export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
      ]
    };
  }
}
