// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject} from 'rxjs';
import {first} from 'rxjs/operators';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Auth
import {AuthNoticeService, AuthService} from '../../../../core/auth';
import {UserQuery} from '@core/query/user.query';
import { UserStore } from '@core/store/user.store';

@Component({
  selector: 'kt-register-doctor',
  templateUrl: './register-doctor.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RegisterDoctorComponent implements OnInit, OnDestroy {
  // Public params
  loginForm: FormGroup;
  loading = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];

  currentTab = 'login'; // 'login' | 'otp'

  error: string;

  private unsubscribe: Subject<any>;

  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private query: UserQuery,
    private store: UserStore,
  ) {
    this.unsubscribe = new Subject();
  }

  async ngOnInit() {
    this.initLoginForm();
    await this.auth.logout();
    this.auth.unsubscribeUser();
    // redirect back to the returnUrl before login

    // this.route.queryParams.subscribe(params => {
    //   console.log(params);
    //   this.returnUrl = params.returnUrl || '/';
    // });
    const queryResult: any = await this.route.paramMap.pipe(
      first(),
    ).toPromise();
    const currentRole = queryResult.get('role');

    // console.log('rol', currentRole);
    if (currentRole !== 'danisan' && currentRole !== 'diyetisyen') {
      // this.router.navigate(['auth/role']);
      // return;
    }

    this.store.update(() => ({
      data: 'doctor'
    }));
    // this.store.update(() => ({
    //   data: currentRole === 'danisan' ? 'patient' : 'doctor',
    // }));

    const role = this.store.getValue().data;
    this.authNoticeService.setDescription(
      role === 'patient'
        ? `Askipo'yla dilediğin yerden diyetisyenine ulaşabilir ve menünü yönetebilirsin.`
        : `Askipo'yla dilediğin yerden danışanlarınla online görüşme yapabilir ve menülerini yönetebilirsin.`
    );
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      phone: [
        '',
        Validators.compose([
          Validators.required,
        ])
      ]
    });
  }

  async submit() {
    this.error = '';
    const controls = this.loginForm.controls;

    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.loading = true;

    const fullPhone: string = controls.phone.value;
    const phone = '+9' + fullPhone
      .replace('(', '')
      .replace(')', '')
      .replace(/\s/g, '');

    const result = await this.auth.wasRegistered(phone);
    if (result.empty) {
      const role = await this.query.data$.pipe(first()).toPromise();

      // if (role === 'doctor') {
      //   this.error = 'Böyle bir hesap bulunamadı.';
      //   this.loading = false;
      //   this.cdr.markForCheck();
      //   return;
      // }

      this.router.navigate(['/auth/register/' + fullPhone]);
    } else {
      const role = await this.query.data$.pipe(first()).toPromise();

      if ((result.isDoctor && role === 'patient') || (!result.isDoctor && role === 'doctor')) {
        this.error = 'Bu hesaba giriş yapamazsın.';
        this.loading = false;
        this.cdr.markForCheck();
        return;
      }

      if (role === 'doctor' && !result.preApproved) {
        this.error = 'Üyeliğin henüz Askipo tarafından onaylanmadı.';
        this.loading = false;
        this.cdr.markForCheck();
        return;
      }

      if (role === 'doctor' && !result.enabledAccounnt) {
        this.error = 'Üyeliğin sonlandırıldı';
        this.loading = false;
        this.cdr.markForCheck();
        return;
      }

      await this.auth.signInAnonymously();
      await this.auth.sendPassword(result.UID, phone);
      this.router.navigate(['/auth/confirm/' + fullPhone]);
    }
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    if (!this.loginForm) {
      return false;
    }

    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
