import { RoleGuard } from './core/auth/_guards/role.guard';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth Guard
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/auth/login/diyetisyen']);

const routes: Routes = [
  {
    path: 'diyetisyen',
    redirectTo: 'auth/login/diyetisyen',
    pathMatch: 'full'
  },
  {
    path: 'danisan',
    redirectTo: 'auth/login/danisan',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./views/pages/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AngularFireAuthGuard, RoleGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/content/shared/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'voice-chat',
        loadChildren: () => import('./views/pages/content/doctor/voice-chat/voice-chat.module').then(m => m.VoiceChatModule),
      },
      {
        path: 'video-chat',
        loadChildren: () => import('./views/pages/content/doctor/video-chat/video-chat.module').then(m => m.VideoChatModule),
      },
      {
        path: 'chat/:id',
        loadChildren: () => import('./views/pages/content/shared/chat/chat.module').then(m => m.ChatModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist', 'psychologist'] }
      },
      {
        path: 'chat/:id/:answer',
        loadChildren: () => import('./views/pages/content/shared/chat/chat.module').then(m => m.ChatModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist', 'psychologist'] }
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/pages/content/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'wallet',
        loadChildren: () => import('./views/pages/content/doctor/wallet/wallet.module').then(m => m.WalletModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist'] }
      },
      {
        path: 'psychologist-wallet',
        loadChildren: () => import('./views/pages/content/doctor/psychologist-wallet/psychologist-wallet.module').then(m => m.PsychologistWalletModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['psychologist'] }
      },
      {
        path: 'appointments',
        loadChildren: () => import('./views/pages/content/doctor/appointment/appointment.module').then(m => m.AppointmentModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist', 'psychologist'] }
      },
      {
        path: 'past-meetings/:id',
        loadChildren: () => import('./views/pages/content/shared/past-meetings/past-meetings.module').then(m => m.PastMeetingsModule),
      },
      {
        path: 'purchase-package/:id',
        loadChildren: () =>
          import('./views/pages/content/patient/purchase-package/purchase-package.module').then(m => m.PurchasePackageModule),
        canActivate: [RoleGuard],
        data: { doctor: false },
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./views/pages/content/patient/settings/patient-settings.module').then(m => m.PatientSettingsModule),
        canActivate: [RoleGuard],
        data: { doctor: false },
      },
      {
        path: 'comments',
        loadChildren: () => import('./views/pages/content/doctor/comments/comments.module').then(m => m.CommentsModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist','sports_coach', 'psychologist']},
      },
      {
        path: 'forum',
        loadChildren: () => import('./views/pages/content/doctor/forum/forum.module').then(m => m.ForumModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist']},
      },
      {
        path: 'meetings',
        loadChildren: () => import('./views/pages/content/shared/meetings/meetings.module').then(m => m.MeetingsModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist', 'psychologist'] }
      },
      {
        path: 'client-list',
        loadChildren: () => import('./views/pages/content/doctor/client-list/client-list.module').then(m => m.ClientListModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist', 'psychologist'] }
      },
      {
        path: 'recipes',
        loadChildren: () => import('./views/pages/content/doctor/recipes/recipes.module').then(m => m.RecipesModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist','sports_coach']}
      },
      {
        path: 'exercise-video-groups',
        loadChildren: () => import('./views/pages/content/doctor/exercise-video-groups/exercise-video-groups.module').then(m => m.ExerciseVideoGroupsModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist', 'sports_coach']}
      },
      {
        path: 'packages',
        loadChildren: () => import('./views/pages/content/doctor/packages/manage/manage-packages.module').then(m => m.ManagePackagesModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist','sports_coach']}
      },
      {
        path: 'mymenus',
        loadChildren: () =>
          import('./views/pages/content/patient/mymenus/mymenus.module').then(
            m => m.MymenusModule
          ),
        canActivate: [RoleGuard],
        data: { doctor: false },
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('./views/pages/content/doctor/clients/clients.module').then(
            m => m.ClientsModule
          ),
        data: { doctor: true, roles: ['nutritionist','sports_coach']},
        canActivate: [RoleGuard],
      },
      {
        path: 'menus',
        loadChildren: () =>
          import('./views/pages/content/doctor/menus/menus.module').then(
            m => m.MenusModule
          ),
        data: { doctor: true, roles: ['nutritionist']},
        canActivate: [RoleGuard],
      },
      {
        path: 'diet-menu',
        loadChildren: () =>
          import('./views/pages/content/doctor/diet-menu/diet-menu.module').then(
            m => m.DietMenuModule
          ),
        data: { doctor: true, roles: ['nutritionist']},
        canActivate: [RoleGuard],
      },
      {
        path: 'diet-program',
        loadChildren: () =>
          import('./views/pages/content/doctor/diet-program/diet-program.module').then(
            m => m.DietProgramModule
          ),
        data: { doctor: true, roles: ['nutritionist','sports_coach']},
        canActivate: [RoleGuard],
      },
      {
        path: 'orders-diet-program',
        loadChildren: () =>
          import('./views/pages/content/doctor/diet-program-orders/diet-program-orders.module').then(
            m => m.DietProgramOrdersModule
          ),
        data: { doctor: true, roles: ['nutritionist', 'sports_coach']},
        canActivate: [RoleGuard],
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/pages/content/shared/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      // {
      //   path: 'mycomments',
      //   loadChildren: () => import('./views/pages/content/patient/comments/patient-comments.module').then(m => m.PatientCommentsModule),
      //   canActivate: [RoleGuard],
      //   data: {doctor: false},
      // },
      {
        path: 'client-profile/:id',
        loadChildren: () => import('./views/pages/content/doctor/client-profile/client-profile.module').then(m => m.ClientProfileModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist','sports_coach', 'psychologist']},
      },
      {
        path: 'client-profile/:id/:tabIndex',
        loadChildren: () => import('./views/pages/content/doctor/client-profile/client-profile.module').then(m => m.ClientProfileModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist','sports_coach', 'psychologist']},
      },
      {
        path: 'client-detail/:id',
        loadChildren: () => import('./views/pages/content/doctor/client-detail/client-detail.module').then(m => m.ClientDetailModule),
        canActivate: [RoleGuard],
        data: { doctor: true, roles: ['nutritionist','sports_coach', 'psychologist']},
      },
      {
        path: 'doctor-details/:id',
        loadChildren: () => import('./views/pages/content/patient/doctor-details/doctor-details.module').then(m => m.DoctorDetailsModule),
        canActivate: [RoleGuard],
        data: { doctor: false },
      },
      {
        path: 'appointment-detail',
        loadChildren: () => import('./views/pages/content/shared/appointment-detail/appointment-detail.module')
          .then(m => m.AppointmentDetailModule),
      },
      {
        path: 'feedback',
        loadChildren: () => import('./views/pages/content/shared/feedback/feedback.module').then(m => m.FeedbackModule)
      },
      {
        path: 'payment',
        loadChildren: () => import('./views/pages/content/patient/payment/payment.module').then(m => m.PaymentModule)
      },
      // {
      //   path: 'builder',
      //   loadChildren: () => import('app/views/theme/content/builder/builder.module').then(m => m.BuilderModule),
      // },
      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
        },
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
