import {Component, OnInit, Input, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { Observable } from 'rxjs';
import { TokboxService } from '../../../../core/services/tokbox.service';
import { ThreadService } from '../../../../core/services/thread.service';

declare var OT: any;
@Component({
  selector: 'kt-tokbox',
  templateUrl: './tokbox.component.html',
  styleUrls: ['./tokbox.component.scss']
})
export class TokboxComponent implements OnInit, OnDestroy {
  public user$: Observable<any>;

  @Input() public userUID: string;
  @Input() public threadID: string;
  @Input() public status: any;

  public type: string;

  public session: any;
  public publisher: any;
  public subscriber: any;
  public time = {
    count: 0,
    text: '00:00:00',
    interval: null
  };
  public initialized: boolean;
  public subscriberAudio: boolean;
  public publisherAudio: boolean;

  constructor(
    private tokboxService: TokboxService,
    private threadService: ThreadService,
    private cd: ChangeDetectorRef
  ) {
    this.subscriberAudio = true;
  }

  async ngOnInit() {
    this.type = this.tokboxService.type;

    if (this.type === 'voice') {
      this.initializeAudio();
      const css = `
        .OT_subscriber {
          z-index: -2 !important;
          width: 1px !important;
          height: 1px !important;
          top: -150px !important;
          left: -150px !important;
        }
        .OT_publisher {
          z-index: -2 !important;
          height: 1px !important;
          width: 1px !important;
          top: -150px !important;
          right: -150px !important;
        }
      `;
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      style.id = 'custom-tokbox';
      head.appendChild(style);
      style.type = 'text/css';
      style.appendChild(document.createTextNode(css));
    } else {
      const element = document.getElementById('custom-tokbox');
      if (element !== null) {
        element.parentNode.removeChild(element);
      }
    }

    const session = await this.tokboxService.initSession(this.threadID);
    if (session) {
      this.session = OT.initSession(session['apiKey'], session['sessionId']);
      this.initialized = true;

      let options;

      if (this.type === 'video') {
        options = {
          width: 200,
          height: 100,
        };
      } else {
        options = {
          width: 1,
          height: 1,
          videoSource: false
        };
      }

      this.publisher = OT.initPublisher('publisher', options);
      this.publisher.setStyle({
        audioLevelDisplayMode: 'off',
        archiveStatusDisplayMode: 'off',
        buttonDisplayMode: 'off',
        nameDisplayMode: 'off'
      });

      this.publisher.publishAudio(this.status.mic);
      this.subscriberAudio = this.status.audio;

      this.session.on({
        streamCreated: (event) => {
          console.log('stream created', event);
          this.subscriber = this.session.subscribe(event.stream, 'subscriber', {
            subscribeToAudio: true,
            subscribeToVideo: this.type === 'video' ? true : false,
            width: '100%',
            height: '100%',
            audioVolume: 100
          });
          this.subscriber.setStyle({
            audioLevelDisplayMode: 'off',
            audioBlockedDisplayMode: 'off',
            buttonDisplayMode: 'off',
          });

          try {
            OT.updateViews();
          } catch (error) {

          }
        },
        streamDestroyed: (event: any) => {
          console.log('stream destroyed', event);
          this.finish();
          try {
            OT.updateViews();
          } catch (error) {

          }
        },
        sessionConnected: (event: any) => {
          console.log('session connected', event);
          this.session.publish(this.publisher);
          try {
            OT.updateViews();
          } catch (error) {

          }
        }
      });

      this.session.connect(session['token']);
    }
  }

  private initializeAudio() {
    this.user$ = this.threadService.doctorDetail(this.userUID);

    this.time.interval = setInterval(() => {
      this.time.count += 1;
      const dateObj = new Date(this.time.count * 1000);
      const hours = dateObj.getUTCHours();
      const minutes = dateObj.getUTCMinutes();
      const seconds = dateObj.getSeconds();

      const timeString = hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds.toString().padStart(2, '0');

      this.time.text = timeString;
      this.cd.detectChanges();
    }, 1000);
  }

  public finish() {
    if (this.session) {
      this.session.unpublish();
    }
    if (this.subscriber) {
      this.session.unsubscribe(this.subscriber);
    }
    if (this.session) {
      this.session.disconnect();
    }

    this.threadService.callChange(this.threadID, this.type === 'video' ? 'video' : 'voice', 0);
  }

  public changeSubscriberVolume() {
    if (!this.subscriber) {
      return false;
    }

    this.subscriberAudio = !this.subscriberAudio;
    this.subscriber.subscribeToAudio(this.subscriberAudio);
  }

  public changeAudioStatus() {
    if (!this.publisher) {
      return false;
    }

    this.publisherAudio = !this.publisherAudio;
    this.publisher.publishAudio(this.publisherAudio);
  }

  public changeVideoStatus = () =>
    this.publisher.publishVideo(!this.publisher.stream.hasVideo)

  fullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      return;
    }
    document.getElementById('rtc').requestFullscreen();
  }

  public ngOnDestroy() {
    this.finish();
    clearInterval(this.time.interval);
  }
}
