export class MenuConfig {
  public defaults: any = {
    aside: {
      self: {},
      doctor: [
        {
          title: 'Ana Sayfa',
          root: true,
          icon: 'fas fa-home',
          page: '/dashboard',
          bullet: 'dot',
        },
        {
          title: 'Görüşmeler',
          root: true,
          icon: 'fas fa-phone-alt',
          page: '/meetings',
          roles : ['nutritionist', 'psychologist']
        },
        {
          title: 'Menüler',
          root: true,
          icon: 'fas fa-utensils',
          page: '/diet-menu',
          roles: ['nutritionist']
        },
        {
          title: 'Online Diyet Satışları',
          root: true,
          icon: 'fas fa-list',
          page: '/wallet',
          roles: ['nutritionist']
        },
        {
          title: 'Online Seans Satışları',
          root: true,
          icon: 'fas fa-list',
          page: '/psychologist-wallet',
          roles: ['psychologist']
        },
        // {
        //   title: 'Diyet Programı Satışları',
        //   root: true,
        //   icon: 'fas fa-list',
        //   page: '/orders-diet-program',
        //   roles: ['nutritionist', 'sports_coach'],
        // },
        // {
        //   title: 'Egzersiz Videoları',
        //   root: true,
        //   icon: 'fas fa-dumbbell',
        //   page: '/exercise-video-groups',
        //   roles: ['nutritionist','sports_coach']
        // },
        // {
        //   title: 'Diyet Programları',
        //   root: true,
        //   icon: 'fab fa-pagelines',
        //   page: '/diet-program',
        //   roles: ['nutritionist', 'sports_coach'],
        // },
        // {
        //   title: 'Tarifler',
        //   root: true,
        //   icon: 'fas fa-book-open',
        //   page: '/recipes',
        //   roles: ['nutritionist','sports_coach']
        // },
        {
          title: 'Danışan Takip',
          root: true,
          icon: 'fas fa-calendar-check',
          page: '/client-list',
          roles: ['nutritionist']
        },
        {
          title: 'Yorumlar',
          root: true,
          icon: 'fas fa-comments',
          page: '/comments',
          roles: ['nutritionist','sports_coach', 'psychologist']
        },
        {
          title: 'Forum',
          root: true,
          icon: 'fas fa-question',
          page: '/forum',
          roles: ['nutritionist','sports_coach', 'psychologist']
        },
        {
          title: 'Müsait Randevu Saatleri',
          root: true,
          icon: 'far fa-calendar-check',
          page: '/appointments',
          roles: ['nutritionist', 'psychologist']
        },
        {
          title: 'Sohbet',
          root: true,
          icon: 'fab fa-rocketchat',
          page: '/chat/view',
          badge: {
            type: 'kt-badge--bold kt-badge-chat',
            value: 'chat'
          },
          roles: ['nutritionist', 'psychologist']
        },
        {
          title: 'Ayarlar',
          root: true,
          icon: 'fas fa-cog',
          page: '/profile/edit',
        },
        {
          title: 'Çıkış Yap',
          root: true,
          icon: 'fas fa-sign-out-alt',
          page: '/auth/login/diyetisyen'
        },
      ],
      patient: [
        {
          title: 'Menüm',
          root: true,
          icon: 'fas fa-utensils',
          page: '/mymenus',
          bullet: 'dot',
        },
        {
          title: 'Diyetisyenler',
          root: true,
          icon: 'fas fa-user-md',
          page: '/dashboard',
          bullet: 'dot',
        },
        {
          title: 'Sohbet',
          root: true,
          icon: 'fab fa-rocketchat',
          page: '/chat/view',
          bullet: 'dot',
          badge: {
            type: 'kt-badge--bold kt-badge-chat',
            value: 'chat'
          }
        },
        {
          title: 'Görüşmeler',
          root: true,
          icon: 'fas fa-phone-alt',
          page: '/meetings',
          bullet: 'dot',
        },
        // {
        //   title: 'Yorumlarım',
        //   root: true,
        //   icon: 'fas fa-comments',
        //   page: '/mycomments',
        //   bullet: 'dot',
        // },
        {
          title: 'Ayarlar',
          root: true,
          icon: 'fas fa-cog',
          page: '/settings',
          bullet: 'dot',
        },
        {
          title: 'Çıkış Yap',
          root: true,
          icon: 'fas fa-sign-out-alt',
          page: '/auth/login/diyetisyen'
        },
      ]
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
