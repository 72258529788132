import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '@core/auth';
import { DietProgram } from '@core/models/diet-program/diet-program.model';
import * as firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DoctorService } from './doctor.service';

@Injectable({ providedIn:'root'})
export class DietProgramService {
  private readonly fireStore: AngularFirestore;
  private readonly doctorService: DoctorService;
  private readonly authService: AuthService;

  clicked = Boolean(false);
  addCardMenuCloseRequest = new BehaviorSubject<string>('');

  constructor(fireStore: AngularFirestore, doctorService: DoctorService, authService: AuthService) {
    this.fireStore = fireStore;
    this.authService = authService;
    this.doctorService = doctorService;
  }

  public programs(enabled: boolean) {
    return this.fireStore.collection('diet_programs', (query) =>
      query.where('author_uid', '==', this.doctorService.getUID())
        .where('is_deleted', '==', false)
        .where('enabled', '==', enabled)
        .orderBy('create_date', 'desc')
    ).valueChanges({ idField: 'id' });
  }

  public activities(activity:string){
    if (activity === 'personal_videos') {
      return this.fireStore.collection(activity).ref
        .where('author_uid','==', this.doctorService.getUID())
        .orderBy('create_date', 'desc').get();
    }
    if(activity === 'exercise_groups'){
      return this.fireStore.collection(activity).ref.where('author_uid','==', this.doctorService.getUID()).orderBy('create_date', 'desc').get();
    }
    if(activity == 'recipes'){
      return this.fireStore.collection(activity).ref.where('author_uid','==', this.doctorService.getUID()).orderBy('create_date', 'desc').get();
    }

    return this.fireStore.collection(activity).ref.orderBy('create_date', 'desc').get();
  }

  public async getImages(){
    let images = [];
    await this.fireStore.collection('diet_program_images').get().toPromise().then(imgs=>{
      imgs.forEach(img=>{
        images.push(img.data());
      });
    });
    return images;
  }

  public async createMenu(data: DietProgram, copy = false) {
    const createIp = await this.authService.ipAddress;

    const categoryIds = data.category_ids ? data.category_ids.map((value) =>
      value.id ? value.id : value) : [];

    if (!copy) {
      var dayList = [];
      for(var i = 0; i < data.day_count; i++){
        dayList.push({activity_list:[]});
      }

      return this.fireStore.collection<DietProgram>('diet_programs').add({
        ...data,
        create_date: firebase.firestore.Timestamp.now().toMillis(),
        create_ip: createIp,
        author_uid: this.doctorService.getUID(),
        day_list: dayList,
        enabled: false,
        requirement_list: [],
        price: 0,
        category_ids: categoryIds,
        is_deleted: false
      });
    }

    return this.fireStore.collection<DietProgram>('diet_programs').add({
      ...data,
      approved: false,
      create_date: firebase.firestore.Timestamp.now().toMillis(),
      create_ip: createIp,
      author_uid: this.doctorService.getUID(),
      enabled: false,
      requirement_list: [],
      price: 0,
      category_ids: categoryIds,
      is_deleted: false
    });
  }

  public async createVideo(videoId: any){
    const createIp = await this.authService.ipAddress;
    return this.fireStore.collection('personal_videos').add({
      video_id: videoId,
      create_date: firebase.firestore.Timestamp.now().toMillis(),
      create_ip: createIp,
      author_uid: this.doctorService.getUID(),
      order:0
    });
  }

  public async deleteVideo(id: string){
    return this.fireStore.collection('personal_videos').doc(id)
      .update({ is_deleted: true });
  }

  public async updateProgram(id:string,data: DietProgram) {
    const categoryIds = data.category_ids ? data.category_ids.map((value) =>
      value.id ? value.id : value) : [];

    data = this.filterOutUndefined(data) as DietProgram;

    return this.fireStore.collection<DietProgram>('diet_programs').doc(id).update({
      ...data,
      calorie_start: data.calorie_start ? data.calorie_start : null,
      calorie_end: data.calorie_end ? data.calorie_end : null,
      category_ids: categoryIds,
    });
  }

  private filterOutUndefined(obj: Record<string, any>): Record<string, any> {
    return Object.keys(obj).reduce((prev, curr) => {
      if (obj[curr]) {
        prev[curr] = obj[curr];
      }

      return prev;
    }, {});
  }

  public async updateProgramVariable(id:string,data:object){
    return this.fireStore.collection('diet_programs').doc(id).update({
      ...data
    });
  }

  updateDayList(id: string,day_list: object[]){
    return this.fireStore.collection('diet_programs').doc(id).update({day_list: day_list});
  }

  // public getProgramDetail(id: string) {
  //   return this.fireStore.collection('diet_programs').doc(id).ref.get()
  //     .then((value) => value.data());
  // }

  public getProgramDetail(id: string) {
    return this.fireStore.collection('diet_programs').doc(id).valueChanges()
  }

  public getProgramDetailPromise(id: string) {
    return this.fireStore.collection('diet_programs').doc(id).get();
  }

  public async wellBeingRequest(request:string,title:string){
    const createIp = await this.authService.ipAddress;
    return this.fireStore.collection('wellbeing_requests').add({
      title:title,
      context:request,
      create_date: firebase.firestore.Timestamp.now().toMillis(),
      author_ip: createIp,
      author_uid: this.doctorService.getUID(),
    });
  }

  public async getCardDatas(){
    var datas = {
      sport:{},
      well_being:{},
      recipe:{},
      personal_video:{},
      partner_product: {}
    };

    const authorId = this.doctorService.getUID();
    await this.fireStore.collection('exercise_groups').ref
      .where('author_uid', '==', authorId)
      .orderBy('create_date', 'desc').get().then(ds => {
        //console.log('Data sonuç', ds.docs)
      ds.forEach(data=>{
        //console.log('data', data);
        var d = data.data();
        d.id = data.id;
        datas.sport[''+d.id] = d;
      });
      return
    });
    console.log(datas);
    await this.fireStore.collection('wellbeing_activities').ref.orderBy('create_date', 'desc').get().then(ds => {
      ds.forEach(data=>{
        var d = data.data();
        d.id = data.id;
        datas.well_being[''+d.id] = d;
      });
      return
    });

    await this.fireStore.collection('recipes').ref.where('author_uid','==', this.doctorService.getUID()).orderBy('create_date', 'desc').get().then(ds => {
      ds.forEach(data=>{
        var d = data.data();
        d.id = data.id;
        datas.recipe[''+d.id] = d;
      });
      return
    });

    await this.fireStore.collection('personal_videos').ref.where('author_uid','==', this.doctorService.getUID()).orderBy('create_date', 'desc').get().then(ds => {
      ds.forEach(data=>{
        var d = data.data();
        d.id = data.id;
        datas.personal_video[''+d.id] = d;
      });
      return
    });

    await this.fireStore.collection('partner_products').ref.where('is_deleted','==', false).orderBy('create_date', 'desc').get().then(ds => {
      ds.forEach(data=>{
        var d = data.data();
        d.id = data.id;
        datas.partner_product[''+d.id] = d;
      });
      return
    });


    return datas;
    //this.fireStore.collection(activity).ref.where('author_uid','==', this.doctorService.getUID()).orderBy('create_date', 'desc').get();
  }

  public async deleteProgram(id:string){
    return await this.fireStore.collection('diet_programs').doc(id).delete();
  }

  /**
   * Set is_deleted field of the diet_program to true.
   * @param id Id of the diet program
   * @returns Promise
   */
  public deleteProgramNew(id: string) {
    return this.fireStore.collection('diet_programs').doc(id)
      .update({ is_deleted: true });
  }

  public async getProgramCategories(){
    let categories = [];
    await this.fireStore.collection('diet_program_categories').get().toPromise().then(cats=>{
      cats.forEach(cat=>{
        var c = cat.data();
        c.id = cat.id;
        categories.push(c);
      });
    });
    return categories;
  }

  public async getProgramPrices(){
    let data = [];
    await this.fireStore.collection('diet_program_prices').ref.orderBy('price','asc').get().then(cats=>{
      cats.forEach(cat=>{
        var c = cat.data();
        c.id = cat.id;
        data.push(c);
      });
    });
    return data;
  }

  async clickedIsEnabled(){
    var x = {value: await this.clicked,date:new Date()};
    //console.log(x);
    return x;
  }

  getProgramStatistics(){
    return this.fireStore.collection('wallets',(query)=>
      query.where('uid','==',this.doctorService.getUID())
    ).valueChanges();
  }

  async getProgramOrders(programId:string){
    let orders = await this.fireStore.collection('iap_orders',(query)=>
      query.where('product_id','==',programId)
      .where('approved','==',true)
    ).valueChanges().pipe(take(1)).toPromise() as any;

    let configured_orders = [];

    await Promise.all(orders.map(async (el: any)=>{
      let user = (await this.fireStore.collection('public_users').doc(el.uid).get().toPromise()).data();
      let names = {
        firstname:'',
        lastname:''
      }

      var name = user.display_name.split(' ');

      if(name.length === 1){
        names.firstname = name[0]
      }else{
        names.lastname = name[name.length - 1];
        name.splice(name.length -1,1);
        name.forEach(to =>{names.firstname += to + ' '});
      }

      configured_orders.push({...el, names: names})
    }));

    return configured_orders;
  }

  public updateFaqs(id:string, faqs:any[]){
    return this.fireStore.collection('diet_programs').doc(id).update({
      faqs: faqs
    });
  }

  // public getAssignClients(type: string) {
  //   const uid = this.doctorService.getUID();
  //   const today = firebase.firestore.Timestamp.now()
  //     .toDate()
  //     .setHours(0, 0, 0, 0);

  //   return this.fireStore.collection('user_packages')
  //     .ref
  //     .where('author_uid', '==', uid)
  //     .where(
  //       'assigned_menu_last_date',
  //       type === 'assigned' ? '>=' : '<=',
  //       today
  //     ).get();
  // }

  // public async getClientMenu(uid: string) {
  //   const pkg = await this.havePackage(uid);
  //   const startDate = moment(pkg.data['pkg_start_date']).startOf('days').toDate().getTime();

  //   const result = await this.fireStore.collection('users')
  //     .doc(uid).collection('diet_records')
  //     .ref.orderBy('date', 'asc')
  //     .where('date', '>=', startDate)
  //     .get();

  //   return result.docs.map((value) => {
  //     return {
  //       id: value.id,
  //       ...value.data()
  //     }
  //   });
  // }

  // public async getClients() {
  //   const activeThreads = await this.fireStore.collection('threads').ref
  //     .where('doctor.uid', '==', this.doctorService.getUID())
  //     .where('status', '==', 1)
  //     .get();

  //   const resultPromise = await Promise.all(activeThreads.docs.map(async (snap) => {
  //     const packageResult = await this.havePackage(snap.get('patient.uid'));

  //     if (packageResult.status) {
  //       return { status: true, ...packageResult.data };
  //     } else {
  //       return { status: false };
  //     }
  //   }));

  //   return resultPromise.filter((query) => query['status'] === true);
  // }

  // private async havePackage(patientUID: string) {
  //   const searchResult = await this.fireStore.collection('user_packages').ref
  //     .where('author_uid', '==', this.doctorService.getUID())
  //     .where('assigned_user.uid', '==', patientUID)
  //     .get();

  //   if (searchResult.empty) {
  //     return { status: false, data: {} };
  //   }

  //   if (searchResult.docs[0].get('is_deleted')) {
  //     return { status: false, data: {} };
  //   }

  //   const pkgs = searchResult.docs[0].get('packages');
  //   const pkgStarDate = pkgs[pkgs.length - 1].purchase_date;
  //   const pkgEndDate = moment(pkgStarDate).add(
  //     pkgs[pkgs.length - 1].months.length, 'months'
  //   ).toDate().getTime();

  //   const data = {
  //     id: searchResult.docs[0].id,
  //     ...searchResult.docs[0].data(),
  //     pkg_start_date: pkgStarDate,
  //     pkg_end_date: pkgEndDate,
  //   };

  //   return { status: true, data };
  // }

  // public menuDetail(id: string) {
  //   return this.fireStore.collection('diet_menus').doc(id).ref.get()
  //     .then((response) => {
  //       return {
  //         ...response.data(),
  //         id: response.id
  //       };
  //     });
  // }

  // public async cloneMenu(id: string) {
  //   const menuDetail = await this.menuDetail(id);
  //   const result = await this.fireStore.collection<DietMenu>('diet_menus').add({
  //     author_uid: this.doctorService.getUID(),
  //     create_date: firebase.firestore.Timestamp.now().toMillis(),
  //     modify_date: firebase.firestore.Timestamp.now().toMillis(),
  //     name: menuDetail['name'] + ' - Klon',
  //     create_ip: await this.doctorService.ipAddress,
  //     clients: [],
  //     week_list: menuDetail['week_list']
  //   });

  //   return result.id;
  // }

  // public deleteMenu(id: string) {
  //   return this.fireStore.collection('diet_menus').doc(id).delete();
  // }

  // public async createMenu(name: string) {
  //   const data: DietMenu = {
  //     author_uid: this.doctorService.getUID(),
  //     create_date: firebase.firestore.Timestamp.now().toMillis(),
  //     modify_date: firebase.firestore.Timestamp.now().toMillis(),
  //     name: name,
  //     create_ip: await this.doctorService.ipAddress,
  //     clients: [],
  //     week_list: [
  //       {
  //         order: 0,
  //         day_list: this.weekList
  //       }
  //     ]
  //   };

  //   const result = await this.fireStore.collection('diet_menus').add(data);
  //   return result.id;
  // }

  // public async updateMenu(data: DietMenu) {
  //   const id = data.id;
  //   delete data['id'];

  //   return this.fireStore.collection('diet_menus').doc(id).update({
  //     ...data,
  //     modify_date: firebase.firestore.Timestamp.now().toMillis(),
  //   });
  // }

  // public async assignMenu(menuId: string, userId: string, startDate: number) {
  //   const menuDetail = await this.menuDetail(menuId);
  //   const recordRef = this.fireStore.collection('users')
  //     .doc(userId).collection('diet_records');

  //   let dayDate = new Date(startDate).setHours(0, 0, 0, 0);
  //   const weekList: Week[] = menuDetail['week_list'];
  //   const records = [];

  //   weekList.forEach((week) => {
  //     week.day_list.forEach((day) => {
  //       day['meal_list'] = day['meal_list'].map((meal) => {
  //         const list: any = meal.list.map((listItem) => {
  //           return {
  //             ...listItem,
  //             type: 'menu'
  //           };
  //         });

  //         return {
  //           ...meal,
  //           list: list
  //         };
  //       });

  //       records.push({
  //         date: dayDate,
  //         meal_list: day.meal_list,
  //         menu_id: menuId
  //       });

  //       dayDate = moment(dayDate).add(1, 'days').toDate().getTime();
  //     });
  //   });

  //   await Promise.all(await records.map(async (record) => {
  //     const haveDate = await recordRef.ref.where('date', '==', record.date).get();
  //     if (!haveDate.empty) {
  //       await haveDate.docs[0].ref.delete();
  //     }

  //     await recordRef.add(record);
  //   }));

  //   const idx = menuDetail['clients'].indexOf(userId);
  //   if (idx === -1) {
  //     await this.fireStore.collection('diet_menus').doc(menuDetail['id']).update({
  //       clients: firebase.firestore.FieldValue.arrayUnion(userId)
  //     });
  //   }

  //   const pkgRef = await this.fireStore.collection('user_packages').ref
  //     .where('assigned_user.uid', '==', userId)
  //     .where('author_uid', '==', this.doctorService.getUID())
  //     .get();

  //   if (pkgRef.empty) {
  //     return Promise.resolve();
  //   }

  //   return pkgRef.docs[0].ref.update({
  //     assigned_menu_last_date: records[records.length - 1]['date']
  //   });
  // }

  // public async updateClientMenu(uid: string, date: number, mealList: Meal[]) {
  //   const queryResult = await this.fireStore.collection('users')
  //     .doc(uid)
  //     .collection('diet_records')
  //     .ref.where('date', '==', date)
  //     .get();

  //   if (queryResult.empty) {
  //     return true;
  //     // return this.fireStore.collection('users').doc(uid)
  //     //   .collection('diet_records').add({
  //     //     date: date,
  //     //     menu_id: 'extra',
  //     //     meal_list: mealList
  //     //   });
  //   }

  //   const doc = queryResult.docs[0].ref;
  //   return doc.update({ meal_list: mealList });
  // }

  // public async addWeekClientMenu(uid: string, date: number) {
  //   const queryResult = await  this.fireStore.collection('users')
  //     .doc(uid)
  //     .collection('diet_records')
  //     .ref.where('date', '==', date)
  //     .get();

  //   if (queryResult.empty) {
  //     return false;
  //   }

  // }
}
